import { Image, StyleSheet, Text, View, Link } from "@react-pdf/renderer";
import Onquest from "../assets/onquest.png";

const styles = StyleSheet.create({
  header: {
    flexDirection: "column",
  },

  header1: {
    flexDirection: "row",
    paddingHorizontal: 15,
    paddingVertical: 15,
    justifyContent: "space-between",
    alignItems: "center",
  },

  headerText1: {
    fontWeight: "medium",
    fontSize: "11px",
  },
  headerRow: {
    display: "flex",
    flexDirection: "row",
    marginTop: 6,
  },

  image: {
    height: "40px",
    width: "110px",
    marginRight: 10,
  },
});

export function CustomHeader({ userName, theme ,id}) {
  return (
    <View style={styles.header}>
      <View
        style={[
          styles.header1,
          {
            backgroundColor: theme.header_background,
            borderBottomWidth: 1,
            borderBottomColor: theme.borderColor,
          },
        ]}
      >
        <View
          style={{
            marginLeft: 20,
          }}
        >
          <Text style={[styles.headerText1, { color: theme["on-surface"] }]}>
            {userName} | PID: ONCxxxxxxx
          </Text>
          <View style={styles.headerRow}>
            <Text style={[styles.headerText1, { color: theme["on-surface"] }]}>
              Tap/Click to access your:
            </Text>

            <Link
              src={`https://reporting-frontend-onquest.pages.dev/${id}`}
              style={{
                textDecoration: "underline",
                paddingHorizontal: "8px",
                marginBottom: 4,
              }}
            >
              <Text
                style={[styles.headerText1, { color: theme.primary_color }]}
              >
                Online Report
              </Text>
            </Link>
          </View>
        </View>
        {/* <Image src={Onquest} style={styles.image} /> */}
      </View>
    </View>
  );
}
