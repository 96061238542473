import { createContext, useContext, useEffect, useState } from "react";
import {
  HealthScoreDetails,
  HealthScoreItem,
  Score,
} from "../utils/interfaces";
import { getRoundedValue, parseResponseValue } from "../utils/helper-functions";
import { diseaseAPI } from "../apis";
import axios from "axios";
import { visibleScoreswithCustomers } from "../utils/constants";
import defaulttheme, { generateTheme } from "../utils/Theme/theme";
import { Theme } from "@mui/material/styles";
import { getFormatttedPrakritiQuestions } from "../apis/apiCall";
import { he } from "date-fns/locale";

interface IAppContext {
  appLoadingDisease: boolean;
  appLoadingScore: boolean;
  showSnackbar: boolean;
  snackbarMessage: string | null;
  snackbarSeverity: any;
  appLoading: boolean;
  hasFilledDetails: boolean;
  disease: any;
  healthScore: any;
  email: string;
  refresh: boolean;
  selectedScore: any;
  filledScore: string[];
  initialPage: number;
  initaPageDetails: any;
  nextAvailableQuestion: any;
  // initaPageDetails: PageDetails;
  canDownloadPdf: boolean;
  appTitle: string;
  openNextQuestionDialog: boolean;
  loadingOpenDialog: boolean;
  userName: string;
  googleApiKey: string;
  expanded: string | false;
  showQuestionDialog: boolean;
  isFullReportAvailable: boolean;
  canUploadPdfonCloud: boolean;
  hasPdfUploaded: boolean;
  sethasPdfUploaded: (val: boolean) => void;
  setCanUploadPdfonCloud: (val: boolean) => void;
  setAppLoadingDisease: (val: boolean) => void;
  setAppLoadingScore: (val: boolean) => void;
  onShowSnackbar: (message: string, severity: string) => any;
  onHideSnackbar: () => void;
  setAppLoading: (val: boolean) => void;
  setHealthScore: (loading: any) => void;
  setDisease: (loading: any) => void;
  setHasFilledDetails: (val: boolean) => void;
  setEmail: (email: string) => void;
  setReferesh: (val: boolean) => void;
  setSelectedScore: (loading: any) => void;
  refreshHealthScore: (val: boolean) => void;
  moveToNextScoreQuestion: () => void;
  getAnswerPercentage: (val: boolean, val2: boolean) => void;
  setLoadingOpenDialog: (val: boolean) => void;
  setopenNextQuestionDialog: (val: boolean) => void;
  setFilledScore: (val: string[]) => void;
  setUserName: (val: string) => void;
  setInitialPage: (val: number | ((prevState: number) => number)) => void;
  setCanDownlaodPdf: (val: boolean) => void;
  setInitialPageDetails: (val: any | ((prevState: any) => any)) => void;
  submitFirstPageDetails: (val: any) => void;
  setNextAvailableQuestion: (val: any | null) => void;
  setAppTitle: (email: string) => void;
  setgoogleApiKey: (val: string) => void;
  setExpanded: (val: string | false) => void;
  setShowQuestionDialog: (val: boolean) => void;
  setisFullReportAvailable: (val: boolean) => void;
  currentUser: keyof typeof visibleScoreswithCustomers;
  setCurrentUser: (val: keyof typeof visibleScoreswithCustomers) => void;
  mode: string;
  setMode: (val: string) => void;
  theme: Theme;
  setTheme: (val: Theme) => void;
  urlScore: boolean;
  setUrlScore: (val: boolean) => void;
  noofDialogOpened: number;
  setNoofDialogOpened: (val: number) => void;
}

export const appContext = createContext<IAppContext>({} as IAppContext);

const useProvideApp = () => {
  const [appLoadingDisease, setAppLoadingDisease] = useState<boolean>(false);
  const [appLoadingScore, setAppLoadingScore] = useState<boolean>(false);
  const [appTitle, setAppTitle] = useState<string>("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState<string>("success");
  const [appLoading, setAppLoading] = useState<boolean>(true);
  const [disease, setDisease] = useState(null);
  const [healthScore, setHealthScore] = useState<any>(null);
  const [hasFilledDetails, setHasFilledDetails] = useState(false);
  const [email, setEmail] = useState("");
  const [refresh, setReferesh] = useState(false);
  const [selectedScore, setSelectedScore] = useState(null);
  const [filledScore, setFilledScore] = useState<string[]>([]);
  const [initialPage, setInitialPage] = useState<number>(0);
  const [canDownloadPdf, setCanDownlaodPdf] = useState<boolean>(false);
  const [nextAvailableQuestion, setNextAvailableQuestion] = useState<
    any | null
  >(null);
  const [openNextQuestionDialog, setopenNextQuestionDialog] =
    useState<boolean>(false);
  const [loadingOpenDialog, setLoadingOpenDialog] = useState<boolean>(false);

  const [initaPageDetails, setInitialPageDetails] = useState<any>({
    client: "madmachines",
    user_id: email!,
  });

  const [googleApiKey, setgoogleApiKey] = useState("");
  const [userName, setUserName] = useState<string>("");
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [showQuestionDialog, setShowQuestionDialog] = useState(false);
  const [isFullReportAvailable, setisFullReportAvailable] = useState(false);
  const [canUploadPdfonCloud, setCanUploadPdfonCloud] = useState(false);
  const [hasPdfUploaded, sethasPdfUploaded] = useState(false);
  const [urlScore, setUrlScore] = useState<boolean>(false);
  const [noofDialogOpened, setNoofDialogOpened] = useState<number>(0);

  const [currentUser, setCurrentUser] =
    useState<keyof typeof visibleScoreswithCustomers>("healthians");

  useEffect(() => {
    // setgoogleApiKey(process.env.REACT_APP_GOOGLE_API_KEY || "");
    setgoogleApiKey("AIzaSyDHuxBA4HzVkra7lvB_yRG1lYf_KvPBT6A");
  }, []);

  const onShowSnackbar = async (message: string, severity: string) => {
    setShowSnackbar(true);
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
  };

  const onHideSnackbar = async () => {
    setShowSnackbar(false);
    setSnackbarMessage(null);
  };

  const [mode, setMode] = useState<string>("light"); // Can be 'light' or 'dark'
  const [theme, setTheme] = useState<Theme>(defaulttheme); // Store the generated theme here

  useEffect(() => {
    const hostname = window.location.hostname;
    const pathname = window.location.pathname;

    // Combine hostname and pathname for easier checking
    const urlContent = `${hostname}${pathname}`.toLowerCase(); // Normalize to lowercase for consistent matching

    if (urlContent.includes("healthians")) {
      setCurrentUser("healthians");
      setMode("light");
      setTheme(generateTheme("light", "healthians"));
    } else if (urlContent.includes("onquest")) {
      setCurrentUser("onquest");
      setMode("light");
      setTheme(generateTheme("light", "onquest"));
    } else if (urlContent.includes("dxai")) {
      setCurrentUser("dxAi");
      setMode("light");
      setTheme(generateTheme("light", "dxAi"));
    }
  }, []);

  const downloadPdfwithUrl = async () => {
    // const pdfUrl = `https://d3ggzbwb0yiai7.cloudfront.net/madmachines/${email}.pdf`; // Replace with your PDF URL
    const pdfUrl = `https://d3ggzbwb0yiai7.cloudfront.net/madmachines/${email}.pdf`; // Replace with your PDF URL
    const filename = `${email.length > 0 ? email : "lab"}_reports.pdf`; // Desired file name

    axios
      .get(pdfUrl, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link?.parentNode?.removeChild(link);
      })
      .catch((error) => {
        console.error("There was an error downloading the file:", error);
      });
  };

  const getAnswerPercentage = async (
    canDownlaod: boolean,
    showMessage: boolean
  ) => {
    const response = await diseaseAPI.checkPercentageScore(email!);
    if (!response) {
      if (disease) {
        if (showMessage) {
          await onShowSnackbar(
            "Your partial report is ready for download.",
            "success"
          );
          if (canDownlaod) setCanDownlaodPdf(true);
        }
      } else {
        await onShowSnackbar("Your report is not ready yet.", "success");
      }
    } else if (response) {
      if (response[0].percentage === 0) {
        if (disease) {
          if (showMessage) {
            await onShowSnackbar(
              "Your partial report is ready for download.",
              "success"
            );

            if (canDownlaod) setCanDownlaodPdf(true);
          }
        } else {
          if (showMessage) {
            await onShowSnackbar("Your report is not ready yet.", "success");
          }
        }
      } else if (response[0].percentage === 100) {
        if (disease) {
          setisFullReportAvailable(true);

          // if (canDownlaod) setCanDownlaodPdf(true);
        } else {
          if (showMessage) {
            await onShowSnackbar(
              "Your partial report is ready for download.",
              "success"
            );

            if (canDownlaod) setCanDownlaodPdf(true);
          }
        }
      } else {
        if (showMessage) {
          await onShowSnackbar(
            "Your partial report is ready for download.",
            "success"
          );

          if (canDownlaod) setCanDownlaodPdf(true);
        }
      }
    }
  };

  const capitalizeWords = (str: string): string => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  useEffect(() => {
    if (disease && Object.keys(disease).length > 0) {
      getAnswerPercentage(false, false);
    }
  }, [disease]);

  const removeDuplicatesAndEmpty = (arr: string[]): string[] => {
    return Array.from(new Set(arr.filter((item) => item !== "")));
  };

  useEffect(() => {
    const getUserDetailsonLoad = async () => {
      try {
        const response = await diseaseAPI.getUserDetails(
          email!,
          currentUser === "healthians" ? "madmachines" : currentUser
        );

        if (response !== null) {
          setHasFilledDetails(true);
          setInitialPage(5);
        }
      } catch (err) {
        console.log("Error:", err);
        await onShowSnackbar(
          "Some thing went wrong while loading User Details",
          "error"
        );
      }
    };

    const getDashBoardDetails = async () => {
      try {
        diseaseAPI
          .getAllDisease(email!)
          .then((res) => {
            if (res && res.name && res.name.length > 0) {
              setUserName(capitalizeWords(res.name));
            }
            if (res && res.risks) {
              const processedRisks = Object.keys(res.risks).reduce(
                (acc: any, riskLevel: string) => {
                  acc[riskLevel] = res.risks[riskLevel].map((risk: any) => {
                    const updatedRisk = { ...risk };

                    if (risk.about && risk.about.length > 0) {
                      updatedRisk.about = removeDuplicatesAndEmpty(risk.about);
                    }

                    if (
                      risk.recommendations &&
                      risk.recommendations.length > 0
                    ) {
                      updatedRisk.recommendations = risk.recommendations.map(
                        (recommendation: any) =>
                          removeDuplicatesAndEmpty(recommendation)
                      );
                    }

                    if (
                      risk["Risk Factors"] &&
                      risk["Risk Factors"].length > 0
                    ) {
                      updatedRisk["Risk Factors"] = removeDuplicatesAndEmpty(
                        risk["Risk Factors"]
                      );
                    }

                    return updatedRisk;
                  });

                  return acc;
                },
                {}
              );

              setDisease(processedRisks);
            }
          })

          .catch(async (err) => {
            console.error(err.message);

            await onShowSnackbar(
              "Some thing went wrong while loading Health Risks",
              "error"
            );
          })
          .finally(() => {
            setAppLoadingDisease(false);
          });

        const scorePromise = diseaseAPI.getAllScores(email!);
        const formattedQuestionsPromise = getFormatttedPrakritiQuestions(
          email!
        );

        // Use Promise.allSettled to handle all promises
        const results = await Promise.allSettled([
          scorePromise,
          formattedQuestionsPromise,
        ]);
        const scoreRes = results[0];
        const formattedQuestionsRes = results[1];

        if (scoreRes.status === "fulfilled") {
          const res = scoreRes.value;
          console.log("res:", res);

          // Good Metabolic Score
          res.recommendations["Metabolic Score"] = {
            ...res.recommendations["Metabolic Score"],
            score:
              res.recommendations["Metabolic Score"].question_responses &&
              res.recommendations["Metabolic Score"].question_responses
                .length === 3
                ? 61
                : null,
            max_score:
              res.recommendations["Metabolic Score"].question_responses &&
              res.recommendations["Metabolic Score"].question_responses
                .length === 3
                ? 100
                : null,
            label:
              res.recommendations["Metabolic Score"].question_responses &&
              res.recommendations["Metabolic Score"].question_responses
                .length === 3
                ? "Good Metabolic Score"
                : null,
            score_label:
              res.recommendations["Metabolic Score"].question_responses &&
              res.recommendations["Metabolic Score"].question_responses
                .length === 3
                ? "Low"
                : null,
            recommendations:
              res.recommendations["Metabolic Score"].question_responses &&
              res.recommendations["Metabolic Score"].question_responses
                .length === 3
                ? {
                    "Risk Factors": [
                      "Obesity",
                      "Sedentary Lifestyle",
                      "Insulin Resistance",
                      "High Blood Pressure",
                      "High Cholesterol",
                      "",
                    ],
                    "About the Score & Risk": [
                      "Good score implies a decreased likelihood of experiencing metabolic abnormalities such as insulin resistance, obesity, and disrupted lipid profiles.",
                      "Higher the score better the metabolic health, unaddressed metabolic abnormalities may progress to disorders like Diabetes Mellitus, CVD, Hypertension, etc.",
                    ],
                    Mitigation_Strategies_Heading: [
                      "To optimize your metabolic health, consider following these recommendations.",
                      "Increased weight is the risk factor for many diseases like diabetes, heart diseases, musculoskeletal disorders like osteoarthritis, sleep disorders like sleep apnea, certain cancers etc.",
                      "Slow & sustainable weight loss should be the target as aggressive weight loss can lead to weight regain.",
                      "Planning meals beforehand helps to avoid excess consumption of unhealthy and junk foods.",
                      "Consuming a variety of grains, legumes, seasonal vegetables and fruits is recommended.",
                      "At least 150 minutes of PA has to be performed in a week. (Recommended)",
                    ],
                    "Score/Label To Display": "Good Metabolic Score",
                  }
                : {},
          };

          // Poor Metabolic Score
          res.recommendations["Metabolic Score"] = {
            ...res.recommendations["Metabolic Score"],
            score:
              res.recommendations["Metabolic Score"].question_responses &&
              res.recommendations["Metabolic Score"].question_responses
                .length === 3
                ? 40
                : null,
            max_score:
              res.recommendations["Metabolic Score"].question_responses &&
              res.recommendations["Metabolic Score"].question_responses
                .length === 3
                ? 100
                : null,
            label:
              res.recommendations["Metabolic Score"].question_responses &&
              res.recommendations["Metabolic Score"].question_responses
                .length === 3
                ? "Poor Metabolic Score"
                : null,
            score_label:
              res.recommendations["Metabolic Score"].question_responses &&
              res.recommendations["Metabolic Score"].question_responses
                .length === 3
                ? "High"
                : null,
            recommendations:
              res.recommendations["Metabolic Score"].question_responses &&
              res.recommendations["Metabolic Score"].question_responses
                .length === 3
                ? {
                    "Risk Factors": [
                      "Obesity",
                      "Sedentary Lifestyle",
                      "Insulin Resistance",
                      "High Blood Pressure",
                      "High Cholesterol",
                    ],
                    "About the Score & Risk": [
                      "Poor score signals a heightened risk of metabolic issues like insulin resistance, obesity, and unhealthy lipid profiles.",
                      "Higher the score better the metabolic health, unaddressed metabolic abnormalities may progress to disorders like Diabetes Mellitus, CVD, Hypertension, etc.",
                    ],
                    Mitigation_Strategies_Heading: [
                      "To improve your metabolic health and reduce future health risks, following these recommendations is crucial.",
                      "Increased weight is the risk factor for many diseases like diabetes, heart diseases, musculoskeletal disorders like osteoarthritis, sleep disorders like sleep apnea, certain cancers etc.",
                      "Slow & sustainable weight loss should be the target as aggressive weight loss can lead to weight regain.",
                      "Planning meals beforehand helps to avoid excess consumption of unhealthy and junk foods.",
                      "Consuming a variety of grains, legumes, seasonal vegetables and fruits is recommended.",
                      "Walking after a meal helps in minimizing the glucose spike.",
                      "At least 150 minutes of PA has to be performed in a week. (Recommended)",
                      "These can help to maintain body composition, glucose regulation and also lipid profile.",
                      "Lean cuts of meat, baked or grilled should be preferred over meats cooked at high temperature & charred excessively.",
                    ],
                    "Score/Label To Display": "Poor Metabolic Score",
                  }
                : {},
          };

          // High Risk Fatty Liver
          res.recommendations["Fatty Liver Score"] = {
            ...res.recommendations["Fatty Liver Score"],
            score:
              res.recommendations["Fatty Liver Score"].question_responses &&
              res.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? 70
                : null,
            max_score:
              res.recommendations["Fatty Liver Score"].question_responses &&
              res.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? 100
                : null,
            label:
              res.recommendations["Fatty Liver Score"].question_responses &&
              res.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? "High Risk Fatty Liver"
                : null,
            score_label:
              res.recommendations["Fatty Liver Score"].question_responses &&
              res.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? "High"
                : null,
            recommendations:
              res.recommendations["Fatty Liver Score"].question_responses &&
              res.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? {
                    "Risk Factors": [
                      "Age",
                      "Genetic Factors",
                      "Poor Diet",
                      "Dyslipidemia",
                      "Insulin Resistance",
                      "Metabolic Syndrome",
                    ],
                    "About the Score & Risk": [
                      "You have a high risk of developing Non Alcoholic Fatty Liver Disease (NAFLD).",
                      "Approx. 38% of Indians suffer from NAFLD.",
                      "Early detection of NAFLD is crucial as it has no proven drug cure, and excess fat accumulation in the liver can lead to irreversible damage.",
                    ],
                    Mitigation_Strategies_Heading: [
                      "To effectively manage your NAFLD risk, it's important to follow these recommendations diligently",
                      "The cornerstone measure for NAFLD prevention involves slow and sustainable weight loss, aiming for 1kg per week of weight loss.",
                      "Even a 5% reduction can significantly improve the condition, the goal here is to maintain the lost weight.",
                      "Active as well as passive smokers are at risk of NAFLD.",
                      "Recommended 150 minutes of weekly physical activity lowers NAFLD risk compared to inactivity.",
                      "Low glycemic index (GI) carbohydrates, MUFAs, PUFAs should be preferred over high GI carbohydrates.",
                      "Red meat should be consumed in limits as it contains high levels of sodium, saturated fats, and trans fats.",
                      "Lean cuts of meat, baked or grilled should be preferred over meats cooked at high temperature & charred excessively.",
                    ],
                    "Score/Label To Display": "High Risk Fatty Liver",
                  }
                : {},
          };

          // Moderate Risk Fatty Liver
          res.recommendations["Fatty Liver Score"] = {
            ...res.recommendations["Fatty Liver Score"],
            score:
              res.recommendations["Fatty Liver Score"].question_responses &&
              res.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? 50
                : null,
            max_score:
              res.recommendations["Fatty Liver Score"].question_responses &&
              res.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? 100
                : null,
            label:
              res.recommendations["Fatty Liver Score"].question_responses &&
              res.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? "Moderate Risk Fatty Liver"
                : null,
            score_label:
              res.recommendations["Fatty Liver Score"].question_responses &&
              res.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? "Medium"
                : null,
            recommendations:
              res.recommendations["Fatty Liver Score"].question_responses &&
              res.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? {
                    "Risk Factors": [
                      "Age",
                      "Genetic Factors",
                      "Poor Diet",
                      "Dyslipidemia",
                      "Insulin Resistance",
                      "Metabolic Syndrome",
                    ],
                    "About the Score & Risk": [
                      "You have a moderate risk of developing Non Alcoholic Fatty Liver Disease (NAFLD).",
                      "Liver fat over 5-10% can cause NAFLD.",
                      "Early detection of NAFLD is crucial as it has no proven drug cure, and excess fat accumulation in the liver can lead to irreversible damage.",
                    ],
                    Mitigation_Strategies_Heading: [
                      "For optimal liver health, especially with a moderate NAFLD risk, following these recommendations is advisable.",
                      "The cornerstone measure for NAFLD prevention involves slow and sustainable weight loss, aiming for 1kg per week of weight loss.",
                      "Even a 5% reduction can significantly improve the condition, the goal here is to maintain the lost weight.",
                      "Active as well as passive smokers are at risk of NAFLD.",
                      "Recommended 150 minutes of weekly physical activity lowers NAFLD risk compared to inactivity.",
                      "Low glycemic index (GI) carbohydrates, MUFAs, PUFAs should be preferred over high GI carbohydrates.",
                      "Red meat should be consumed in limits as it contains high levels of sodium, saturated fats, and trans fats.",
                      "Lean cuts of meat, baked or grilled should be preferred over meats cooked at high temperature & charred excessively.",
                    ],
                    "Score/Label To Display": "Moderate Risk Fatty Liver",
                  }
                : {},
          };

          // Low Risk Fatty Liver
          res.recommendations["Fatty Liver Score"] = {
            ...res.recommendations["Fatty Liver Score"],
            score:
              res.recommendations["Fatty Liver Score"].question_responses &&
              res.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? 0
                : null,
            max_score:
              res.recommendations["Fatty Liver Score"].question_responses &&
              res.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? 100
                : null,
            label:
              res.recommendations["Fatty Liver Score"].question_responses &&
              res.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? "Low Risk Fatty Liver"
                : null,
            score_label:
              res.recommendations["Fatty Liver Score"].question_responses &&
              res.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? "Low"
                : null,
            recommendations:
              res.recommendations["Fatty Liver Score"].question_responses &&
              res.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? {
                    "Risk Factors": [
                      "Age",
                      "Genetic Factors",
                      "Poor Diet",
                      "Dyslipidemia",
                      "Insulin Resistance",
                      "Metabolic Syndrome",
                    ],
                    "About the Score & Risk": [
                      "You have a low risk of developing Non Alcoholic Fatty Liver Disease (NAFLD).",
                      "Stable weight and blood glucose lower NAFLD.",
                      "It is characterised by excess of fat accumulation in the liver.",
                    ],
                    Mitigation_Strategies_Heading: [
                      "While your NAFLD risk is low, incorporating these recommendations can further promote overall well-being, including liver health",
                      "Maintaining ideal body weight & body composition will further help to keep risk of NAFLD at lower end of the spectrum.",
                      "Recommended 150 minutes of weekly physical activity also lowers NAFLD risk compared to inactivity.",
                      "Balanced diet containing low glycemic index carbohydrates, proteins & MUFAs & PUFAs will aid in further reducing  risk of NAFLD.",
                    ],
                    "Score/Label To Display": "Low Risk Fatty Liver",
                  }
                : {},
          };

          if (
            visibleScoreswithCustomers[currentUser].includes("Prakriti Score")
          ) {
            if (formattedQuestionsRes.status === "fulfilled") {
              const formattedQuestions = formattedQuestionsRes.value;
              if (formattedQuestions && formattedQuestions.questions) {
                if ("Prakriti Score" in res.recommendations) {
                  // Update the questions for existing "Prakriti Score"
                  res.recommendations["Prakriti Score"].questions =
                    formattedQuestions.questions;
                } else {
                  // Create a new "Prakriti Score" with default values
                  res.recommendations["Prakriti Score"] = {
                    label: null,
                    max_score: null,
                    questions: formattedQuestions.questions,
                    recommendations: {},
                    score: null,
                    score_label: null,
                  };
                }
              }
            } else {
              console.error(
                "Error fetching formatted Prakriti questions:",
                formattedQuestionsRes.reason
              );
              await onShowSnackbar(
                "Something went wrong while loading Prakriti questions",
                "error"
              );
            }
          }

          // Handle Vikriti Score
          if ("Vikriti" in res.recommendations) {
            const vikritiScore = res.recommendations["Vikriti"] as Score;

            // Ensure vikritiScore.recommendations exists, or initialize it as an empty object
            const existingRecommendations = vikritiScore.recommendations || {};

            // Modify vikritiScore.recommendations
            const updatedRecommendations = {
              ...existingRecommendations,
              "About the Score & Risk": [],
              "Risk Factors": [],
            };

            // Add default fields if necessary
            res.recommendations["Vikriti"] = {
              label: vikritiScore.label || null,
              max_score: vikritiScore.max_score || null, // Set a default max_score if not present
              questions: vikritiScore.questions || [], // Ensure questions array exists
              recommendations: updatedRecommendations, // Ensure recommendations object exists
              score: vikritiScore.score || null, // Default score if not present
              score_label: res.recommendations["Vikriti"].score_level || null, // Default score label if not present
            };
          }

          const healthScoreData: HealthScoreDetails = {
            name:
              res.recommendations.name && res.recommendations.name.length > 0
                ? capitalizeWords(res.recommendations.name)
                : "",
            recommendations: res?.recommendations
              ? Object.entries(res.recommendations)
                  .filter(([key, _]) =>
                    visibleScoreswithCustomers[currentUser].includes(key)
                  ) // Filter out the score dependig on the customer
                  .map(([key, value]) => {
                    // Check if the key is 'Prakriti Score', set title to 'Prakriti' in that case
                    const title = key === "Prakriti Score" ? "Prakriti" : key;

                    return {
                      title, // Set the modified title
                      score: value as Score, // Cast value to Score
                    };
                  })
              : [],
          };

          if (healthScoreData.name && healthScoreData.name.length > 0) {
            setUserName(healthScoreData.name);
          }

          healthScoreData.recommendations.map((score) => {
            if (score.title === "Physical Activity Score") {
              score.score.score = getRoundedValue(score.score.score, 45, true);
            }
            if (score.title === "Metabolic Score" && score.score.score) {
              score.score.score = getRoundedValue(score.score.score, 100, true);
            }
            if (score.title === "Stress Score") {
              score.score.score = getRoundedValue(score.score.score, 40, false);
            }
            if (score.title === "Sleep Score") {
              score.score.score = getRoundedValue(score.score.score, 18, false);
            }
            if (score.title === "Fatty Liver Score") {
              score.score.score = getRoundedValue(
                score.score.score,
                100,
                false
              );
            }
            if (score.title === "Prakriti Score") {
              score.score.score = getRoundedValue(score.score.score, 45, true);
            }
          });

          const sortedData: HealthScoreItem[] =
            healthScoreData.recommendations.map((item: HealthScoreItem) => {
              return {
                ...item,
                score: {
                  ...item.score,
                  questions: Array.isArray(item.score.questions)
                    ? item.score.questions.sort((a, b) => {
                        const numX = parseInt(a.question_id.split("_")[1], 10);
                        const numY = parseInt(b.question_id.split("_")[1], 10);

                        return numX - numY;
                      })
                    : [],
                },
              };
            });

          healthScoreData.recommendations.map((score, i) => {
            if (
              score.score.recommendations &&
              Object.keys(score.score.recommendations).length > 0
            ) {
              setFilledScore((prev) => [...prev, score.title]);
            }
            if (score.score.questions) {
              score.score.questions.map((q, j) => {
                if (
                  healthScoreData.recommendations[i].score.question_responses &&
                  healthScoreData.recommendations[i].score.question_responses
                    .length > 0
                ) {
                  healthScoreData.recommendations[
                    i
                  ].score.question_responses.map((res) => {
                    if (res.question_id === q.question_id) {
                      if (
                        res.response_value !== -1 ||
                        res.response_value !== "-1"
                      ) {
                        if (typeof res.response_value === "number") {
                          healthScoreData.recommendations[i].score.questions[
                            j
                          ].selected_ans = [res.response_value];
                        } else {
                          healthScoreData.recommendations[i].score.questions[
                            j
                          ].selected_ans = parseResponseValue(
                            res.response_value,
                            res.question_id,
                            healthScoreData.recommendations[i].score.questions[
                              j
                            ].question_values
                          );
                        }
                      }
                    }
                  });
                }
              });
            }
          });

          /* This is store the score tat have been answered */
          const initialArray = new Array(sortedData.length).fill("");

          // Find the index of "Vikriti" in sortedData
          const vikritiIndex = sortedData.findIndex(
            (item) => item.title === "Vikriti"
          );

          // If Vikriti is found, update the initialArray at the found index
          if (vikritiIndex !== -1) {
            initialArray[vikritiIndex] = "Vikriti";
          }
          if (sortedData && sortedData.length > 0) {
            sortedData.map((e: any, i: number) => {
              if (
                e.score.recommendations &&
                Object.keys(e.score.recommendations).length > 0
              ) {
                if (e.title === "Physical Activity Score") {
                  if (
                    e.score.question_responses &&
                    e.score.question_responses.length > 6
                  ) {
                    initialArray[i] = e.title;
                  }
                } else if (
                  e.score.question_responses &&
                  e.score.question_responses.length === e.score.questions.length
                ) {
                  initialArray[i] = e.title;
                }
              }
            });
          }

          let foundNextQuestion = false;
          if (sortedData && sortedData.length > 0) {
            for (let i = 0; i < sortedData.length; i++) {
              if (initialArray[i].length === 0) {
                foundNextQuestion = true;
                setNextAvailableQuestion(sortedData[i]);
                break;
              }
            }
            if (!foundNextQuestion) {
              setSelectedScore(null);
              setNextAvailableQuestion(null);
              sethasPdfUploaded(true);
            }
          }

          setFilledScore(initialArray);
          setHealthScore(sortedData);
          setAppLoadingScore(false);
        } else {
          console.error("Error fetching score data:", scoreRes.reason);
          await onShowSnackbar(
            "Something went wrong while loading score data",
            "error"
          );
          setAppLoadingScore(false);
        }
      } catch (err) {
        console.log("Error:", err);
      }
    };

    const callApi = async () => {
      if (email && email.length > 0) {
        if (refresh) {
          await getDashBoardDetails();
        } else if (!hasFilledDetails) {
          setAppLoading(true);
          setAppLoadingDisease(true);
          setAppLoadingScore(true);
          await getUserDetailsonLoad();
        } else {
          await getDashBoardDetails();
        }
      }
      setAppLoading(false);
      setAppLoadingDisease(false);
      setAppLoadingScore(false);
      setReferesh(false);
    };

    callApi();
  }, [hasFilledDetails, email, setHasFilledDetails]);

  const submitFirstPageDetails = (userDateTime: any) => {
    setAppLoading(true);
    if (currentUser !== "healthians") {
      userDateTime.client = currentUser;
    }

    diseaseAPI
      .saveInitialPageUserDetails(userDateTime)
      .then((res) => {
        // setHasFilledDetails(true);
        refreshHealthScore(false);

        // setInitialPage(3)
      })
      .catch((err) => {
        console.log("Err:", err.message);
        setInitialPage(0);
      });
  };

  const refreshHealthScore = async (loadUpdateScore: boolean = true) => {
    const scorePromise = diseaseAPI.getAllScores(email!);
    const formattedQuestionsPromise = getFormatttedPrakritiQuestions(email!);

    // Use Promise.allSettled to handle all promises
    const results = await Promise.allSettled([
      scorePromise,
      formattedQuestionsPromise,
    ]);

    const scoreRes = results[0];
    const formattedQuestionsRes = results[1];

    if (scoreRes.status === "fulfilled") {
      const res = scoreRes.value;

      // Good Metabolic Score
      res.recommendations["Metabolic Score"] = {
        ...res.recommendations["Metabolic Score"],
        score:
          res.recommendations["Metabolic Score"].question_responses &&
          res.recommendations["Metabolic Score"].question_responses.length === 3
            ? 61
            : null,
        max_score:
          res.recommendations["Metabolic Score"].question_responses &&
          res.recommendations["Metabolic Score"].question_responses.length === 3
            ? 100
            : null,
        label:
          res.recommendations["Metabolic Score"].question_responses &&
          res.recommendations["Metabolic Score"].question_responses.length === 3
            ? "Good Metabolic Score"
            : null,
        score_label:
          res.recommendations["Metabolic Score"].question_responses &&
          res.recommendations["Metabolic Score"].question_responses.length === 3
            ? "Low"
            : null,
        recommendations:
          res.recommendations["Metabolic Score"].question_responses &&
          res.recommendations["Metabolic Score"].question_responses.length === 3
            ? {
                "Risk Factors": [
                  "Obesity",
                  "Sedentary Lifestyle",
                  "Insulin Resistance",
                  "High Blood Pressure",
                  "High Cholesterol",
                  "",
                ],
                "About the Score & Risk": [
                  "Good score implies a decreased likelihood of experiencing metabolic abnormalities such as insulin resistance, obesity, and disrupted lipid profiles.",
                  "Higher the score better the metabolic health, unaddressed metabolic abnormalities may progress to disorders like Diabetes Mellitus, CVD, Hypertension, etc.",
                ],
                Mitigation_Strategies_Heading: [
                  "To optimize your metabolic health, consider following these recommendations.",
                  "Increased weight is the risk factor for many diseases like diabetes, heart diseases, musculoskeletal disorders like osteoarthritis, sleep disorders like sleep apnea, certain cancers etc.",
                  "Slow & sustainable weight loss should be the target as aggressive weight loss can lead to weight regain.",
                  "Planning meals beforehand helps to avoid excess consumption of unhealthy and junk foods.",
                  "Consuming a variety of grains, legumes, seasonal vegetables and fruits is recommended.",
                  "At least 150 minutes of PA has to be performed in a week. (Recommended)",
                ],
                "Score/Label To Display": "Good Metabolic Score",
              }
            : {},
      };

      // Poor Metabolic Score
      res.recommendations["Metabolic Score"] = {
        ...res.recommendations["Metabolic Score"],
        score:
          res.recommendations["Metabolic Score"].question_responses &&
          res.recommendations["Metabolic Score"].question_responses.length === 3
            ? 40
            : null,
        max_score:
          res.recommendations["Metabolic Score"].question_responses &&
          res.recommendations["Metabolic Score"].question_responses.length === 3
            ? 100
            : null,
        label:
          res.recommendations["Metabolic Score"].question_responses &&
          res.recommendations["Metabolic Score"].question_responses.length === 3
            ? "Poor Metabolic Score"
            : null,
        score_label:
          res.recommendations["Metabolic Score"].question_responses &&
          res.recommendations["Metabolic Score"].question_responses.length === 3
            ? "High"
            : null,
        recommendations:
          res.recommendations["Metabolic Score"].question_responses &&
          res.recommendations["Metabolic Score"].question_responses.length === 3
            ? {
                "Risk Factors": [
                  "Obesity",
                  "Sedentary Lifestyle",
                  "Insulin Resistance",
                  "High Blood Pressure",
                  "High Cholesterol",
                ],
                "About the Score & Risk": [
                  "Poor score signals a heightened risk of metabolic issues like insulin resistance, obesity, and unhealthy lipid profiles.",
                  "Higher the score better the metabolic health, unaddressed metabolic abnormalities may progress to disorders like Diabetes Mellitus, CVD, Hypertension, etc.",
                ],
                Mitigation_Strategies_Heading: [
                  "To improve your metabolic health and reduce future health risks, following these recommendations is crucial.",
                  "Increased weight is the risk factor for many diseases like diabetes, heart diseases, musculoskeletal disorders like osteoarthritis, sleep disorders like sleep apnea, certain cancers etc.",
                  "Slow & sustainable weight loss should be the target as aggressive weight loss can lead to weight regain.",
                  "Planning meals beforehand helps to avoid excess consumption of unhealthy and junk foods.",
                  "Consuming a variety of grains, legumes, seasonal vegetables and fruits is recommended.",
                  "Walking after a meal helps in minimizing the glucose spike.",
                  "At least 150 minutes of PA has to be performed in a week. (Recommended)",
                  "These can help to maintain body composition, glucose regulation and also lipid profile.",
                  "Lean cuts of meat, baked or grilled should be preferred over meats cooked at high temperature & charred excessively.",
                ],
                "Score/Label To Display": "Poor Metabolic Score",
              }
            : {},
      };

      // High Risk Fatty Liver
      res.recommendations["Fatty Liver Score"] = {
        ...res.recommendations["Fatty Liver Score"],
        score:
          res.recommendations["Fatty Liver Score"].question_responses &&
          res.recommendations["Fatty Liver Score"].question_responses.length ===
            3
            ? 70
            : null,
        max_score:
          res.recommendations["Fatty Liver Score"].question_responses &&
          res.recommendations["Fatty Liver Score"].question_responses.length ===
            3
            ? 100
            : null,
        label:
          res.recommendations["Fatty Liver Score"].question_responses &&
          res.recommendations["Fatty Liver Score"].question_responses.length ===
            3
            ? "High Risk Fatty Liver"
            : null,
        score_label:
          res.recommendations["Fatty Liver Score"].question_responses &&
          res.recommendations["Fatty Liver Score"].question_responses.length ===
            3
            ? "High"
            : null,
        recommendations:
          res.recommendations["Fatty Liver Score"].question_responses &&
          res.recommendations["Fatty Liver Score"].question_responses.length ===
            3
            ? {
                "Risk Factors": [
                  "Age",
                  "Genetic Factors",
                  "Poor Diet",
                  "Dyslipidemia",
                  "Insulin Resistance",
                  "Metabolic Syndrome",
                ],
                "About the Score & Risk": [
                  "You have a high risk of developing Non Alcoholic Fatty Liver Disease (NAFLD).",
                  "Approx. 38% of Indians suffer from NAFLD.",
                  "Early detection of NAFLD is crucial as it has no proven drug cure, and excess fat accumulation in the liver can lead to irreversible damage.",
                ],
                Mitigation_Strategies_Heading: [
                  "To effectively manage your NAFLD risk, it's important to follow these recommendations diligently",
                  "The cornerstone measure for NAFLD prevention involves slow and sustainable weight loss, aiming for 1kg per week of weight loss.",
                  "Even a 5% reduction can significantly improve the condition, the goal here is to maintain the lost weight.",
                  "Active as well as passive smokers are at risk of NAFLD.",
                  "Recommended 150 minutes of weekly physical activity lowers NAFLD risk compared to inactivity.",
                  "Low glycemic index (GI) carbohydrates, MUFAs, PUFAs should be preferred over high GI carbohydrates.",
                  "Red meat should be consumed in limits as it contains high levels of sodium, saturated fats, and trans fats.",
                  "Lean cuts of meat, baked or grilled should be preferred over meats cooked at high temperature & charred excessively.",
                ],
                "Score/Label To Display": "High Risk Fatty Liver",
              }
            : {},
      };

      // Moderate Risk Fatty Liver
      res.recommendations["Fatty Liver Score"] = {
        ...res.recommendations["Fatty Liver Score"],
        score:
          res.recommendations["Fatty Liver Score"].question_responses &&
          res.recommendations["Fatty Liver Score"].question_responses.length ===
            3
            ? 50
            : null,
        max_score:
          res.recommendations["Fatty Liver Score"].question_responses &&
          res.recommendations["Fatty Liver Score"].question_responses.length ===
            3
            ? 100
            : null,
        label:
          res.recommendations["Fatty Liver Score"].question_responses &&
          res.recommendations["Fatty Liver Score"].question_responses.length ===
            3
            ? "Moderate Risk Fatty Liver"
            : null,
        score_label:
          res.recommendations["Fatty Liver Score"].question_responses &&
          res.recommendations["Fatty Liver Score"].question_responses.length ===
            3
            ? "Medium"
            : null,
        recommendations:
          res.recommendations["Fatty Liver Score"].question_responses &&
          res.recommendations["Fatty Liver Score"].question_responses.length ===
            3
            ? {
                "Risk Factors": [
                  "Age",
                  "Genetic Factors",
                  "Poor Diet",
                  "Dyslipidemia",
                  "Insulin Resistance",
                  "Metabolic Syndrome",
                ],
                "About the Score & Risk": [
                  "You have a moderate risk of developing Non Alcoholic Fatty Liver Disease (NAFLD).",
                  "Liver fat over 5-10% can cause NAFLD.",
                  "Early detection of NAFLD is crucial as it has no proven drug cure, and excess fat accumulation in the liver can lead to irreversible damage.",
                ],
                Mitigation_Strategies_Heading: [
                  "For optimal liver health, especially with a moderate NAFLD risk, following these recommendations is advisable.",
                  "The cornerstone measure for NAFLD prevention involves slow and sustainable weight loss, aiming for 1kg per week of weight loss.",
                  "Even a 5% reduction can significantly improve the condition, the goal here is to maintain the lost weight.",
                  "Active as well as passive smokers are at risk of NAFLD.",
                  "Recommended 150 minutes of weekly physical activity lowers NAFLD risk compared to inactivity.",
                  "Low glycemic index (GI) carbohydrates, MUFAs, PUFAs should be preferred over high GI carbohydrates.",
                  "Red meat should be consumed in limits as it contains high levels of sodium, saturated fats, and trans fats.",
                  "Lean cuts of meat, baked or grilled should be preferred over meats cooked at high temperature & charred excessively.",
                ],
                "Score/Label To Display": "Moderate Risk Fatty Liver",
              }
            : {},
      };

      // Low Risk Fatty Liver
      res.recommendations["Fatty Liver Score"] = {
        ...res.recommendations["Fatty Liver Score"],
        score:
          res.recommendations["Fatty Liver Score"].question_responses &&
          res.recommendations["Fatty Liver Score"].question_responses.length ===
            3
            ? 0
            : null,
        max_score:
          res.recommendations["Fatty Liver Score"].question_responses &&
          res.recommendations["Fatty Liver Score"].question_responses.length ===
            3
            ? 100
            : null,
        label:
          res.recommendations["Fatty Liver Score"].question_responses &&
          res.recommendations["Fatty Liver Score"].question_responses.length ===
            3
            ? "Low Risk Fatty Liver"
            : null,
        score_label:
          res.recommendations["Fatty Liver Score"].question_responses &&
          res.recommendations["Fatty Liver Score"].question_responses.length ===
            3
            ? "Low"
            : null,
        recommendations:
          res.recommendations["Fatty Liver Score"].question_responses &&
          res.recommendations["Fatty Liver Score"].question_responses.length ===
            3
            ? {
                "Risk Factors": [
                  "Age",
                  "Genetic Factors",
                  "Poor Diet",
                  "Dyslipidemia",
                  "Insulin Resistance",
                  "Metabolic Syndrome",
                ],
                "About the Score & Risk": [
                  "You have a low risk of developing Non Alcoholic Fatty Liver Disease (NAFLD).",
                  "Stable weight and blood glucose lower NAFLD.",
                  "It is characterised by excess of fat accumulation in the liver.",
                ],
                Mitigation_Strategies_Heading: [
                  "While your NAFLD risk is low, incorporating these recommendations can further promote overall well-being, including liver health",
                  "Maintaining ideal body weight & body composition will further help to keep risk of NAFLD at lower end of the spectrum.",
                  "Recommended 150 minutes of weekly physical activity also lowers NAFLD risk compared to inactivity.",
                  "Balanced diet containing low glycemic index carbohydrates, proteins & MUFAs & PUFAs will aid in further reducing  risk of NAFLD.",
                ],
                "Score/Label To Display": "Low Risk Fatty Liver",
              }
            : {},
      };

      if (visibleScoreswithCustomers[currentUser].includes("Prakriti Score")) {
        if (formattedQuestionsRes.status === "fulfilled") {
          const formattedQuestions = formattedQuestionsRes.value;
          if (formattedQuestions && formattedQuestions.questions) {
            if ("Prakriti Score" in res.recommendations) {
              // Update the questions for existing "Prakriti Score"
              res.recommendations["Prakriti Score"].questions =
                formattedQuestions.questions;
            } else {
              // Create a new "Prakriti Score" with default values
              res.recommendations["Prakriti Score"] = {
                label: null,
                max_score: null,
                questions: formattedQuestions.questions,
                recommendations: {},
                score: null,
                score_label: null,
              };
            }
          }
        } else {
          console.error(
            "Error fetching formatted Prakriti questions:",
            formattedQuestionsRes.reason
          );
          await onShowSnackbar(
            "Something went wrong while loading Prakriti questions",
            "error"
          );
        }
      }

      // Handle Vikriti Score
      if ("Vikriti" in res.recommendations) {
        const vikritiScore = res.recommendations["Vikriti"] as Score;

        // Ensure vikritiScore.recommendations exists, or initialize it as an empty object
        const existingRecommendations = vikritiScore.recommendations || {};

        // Modify vikritiScore.recommendations
        const updatedRecommendations = {
          ...existingRecommendations,
          "About the Score & Risk": [],
          "Risk Factors": [],
        };

        // Add default fields if necessary
        res.recommendations["Vikriti"] = {
          label: vikritiScore.label || null,
          max_score: vikritiScore.max_score || null, // Set a default max_score if not present
          questions: vikritiScore.questions || [], // Ensure questions array exists
          recommendations: updatedRecommendations, // Ensure recommendations object exists
          score: vikritiScore.score || null, // Default score if not present
          score_label: res.recommendations["Vikriti"].score_level || null, // Default score label if not present
        };
      }

      const healthScoreData: HealthScoreDetails = {
        name:
          res.recommendations.name && res.recommendations.name.length > 0
            ? capitalizeWords(res.recommendations.name)
            : "",
        recommendations: res?.recommendations
          ? Object.entries(res.recommendations)
              .filter(([key, _]) =>
                visibleScoreswithCustomers[currentUser].includes(key)
              ) // Filter out the score dependig on the customer

              .map(([key, value]) => ({
                title: key,
                score: value as Score, // Cast value to Score
              }))
          : [],
      };

      if (healthScoreData.name && healthScoreData.name.length > 0)
        setUserName(healthScoreData.name);

      healthScoreData.recommendations.map((score) => {
        if (score.title === "Physical Activity Score") {
          score.score.score = getRoundedValue(score.score.score, 45, true);
        }
        if (score.title === "Metabolic Score" && score.score.score) {
          score.score.score = getRoundedValue(score.score.score, 100, true);
        }
        if (score.title === "Stress Score") {
          score.score.score = getRoundedValue(score.score.score, 40, false);
        }
        if (score.title === "Sleep Score") {
          score.score.score = getRoundedValue(score.score.score, 18, false);
        }
        if (score.title === "Fatty Liver Score") {
          score.score.score = getRoundedValue(score.score.score, 100, false);
        }
      });

      const sortedData: HealthScoreItem[] = healthScoreData.recommendations.map(
        (item: HealthScoreItem) => {
          return {
            ...item,
            score: {
              ...item.score,
              questions: Array.isArray(item.score.questions)
                ? item.score.questions.sort((a, b) => {
                    const numX = parseInt(a.question_id.split("_")[1], 10);
                    const numY = parseInt(b.question_id.split("_")[1], 10);
                    return numX - numY;
                  })
                : [],
            },
          };
        }
      );

      healthScoreData.recommendations.map((score, i) => {
        if (
          score.score.recommendations &&
          Object.keys(score.score.recommendations).length > 0
        ) {
          setFilledScore((prev) => [...prev, score.title]);
        }

        if (score.score.questions) {
          score.score.questions.map((q, j) => {
            if (
              healthScoreData.recommendations[i].score.question_responses &&
              healthScoreData.recommendations[i].score.question_responses
                .length > 0
            ) {
              healthScoreData.recommendations[i].score.question_responses.map(
                (res) => {
                  if (res.question_id === q.question_id) {
                    if (
                      res.response_value !== -1 ||
                      res.response_value !== "-1"
                    ) {
                      if (typeof res.response_value === "number") {
                        healthScoreData.recommendations[i].score.questions[
                          j
                        ].selected_ans = [res.response_value];
                      } else {
                        healthScoreData.recommendations[i].score.questions[
                          j
                        ].selected_ans = parseResponseValue(
                          res.response_value,
                          res.question_id,
                          healthScoreData.recommendations[i].score.questions[j]
                            .question_values
                        );
                      }
                    }
                  }
                }
              );
            }
          });
        }
      });

      if (!hasPdfUploaded) {
        /* This is store the score that has been answered */
        const initialArray = new Array(sortedData.length).fill(""); // Adjust the array size based on filtered data

        // Find the index of "Vikriti" in sortedData
        const vikritiIndex = sortedData.findIndex(
          (item) => item.title === "Vikriti"
        );

        // If Vikriti is found, update the initialArray at the found index
        if (vikritiIndex !== -1) {
          initialArray[vikritiIndex] = "Vikriti";
        }

        if (sortedData && sortedData.length > 0) {
          sortedData.map((e: any, i: number) => {
            if (loadUpdateScore && selectedScore) {
              /* This is used when we call reLoad after filling all question for score 
              and we want the updated label and other things for this score */
              if (e.title === selectedScore["title"]) {
                setSelectedScore(e);
              }
            }

            if (
              e.score.recommendations &&
              Object.keys(e.score.recommendations).length > 0
            ) {
              if (e.title === "Physical Activity Score") {
                if (
                  e.score.question_responses &&
                  e.score.question_responses.length > 6
                ) {
                  initialArray[i] = e.title;
                }
              } else if (
                e.score.question_responses &&
                e.score.question_responses.length === e.score.questions.length
              ) {
                initialArray[i] = e.title;
              }
            }
          });
        }

        if (loadUpdateScore) {
          let foundNextQuestion = false;
          if (sortedData && sortedData.length > 0) {
            for (let i = 0; i < sortedData.length; i++) {
              if (initialArray[i].length === 0) {
                foundNextQuestion = true;
                setNextAvailableQuestion(sortedData[i]);
                break;
              }
            }
            if (!foundNextQuestion) {
              setNextAvailableQuestion(null);
              setCanDownlaodPdf(true);
              sethasPdfUploaded(true);
              setCanUploadPdfonCloud(true);
            }
          }
        }
        setFilledScore(initialArray);

        setAppLoadingScore(false);
        setAppLoading(false);
      }

      setHealthScore(sortedData);
    } else {
      await onShowSnackbar(
        "Something went wrong while loading score data",
        "error"
      );
      setAppLoadingScore(false);
      setAppLoading(false);
    }
  };

  const moveToNextScoreQuestion = () => {
    setSelectedScore(nextAvailableQuestion);
  };

  return {
    appLoadingDisease,
    appLoadingScore,
    appTitle,
    showSnackbar,
    snackbarMessage,
    snackbarSeverity,
    appLoading,
    disease,
    healthScore,
    hasFilledDetails,
    email,
    refresh,
    selectedScore,
    filledScore,
    initaPageDetails,
    initialPage,
    canDownloadPdf,
    nextAvailableQuestion,
    loadingOpenDialog,
    openNextQuestionDialog,
    userName,
    googleApiKey,
    expanded,
    showQuestionDialog,
    isFullReportAvailable,
    canUploadPdfonCloud,
    hasPdfUploaded,
    urlScore,
    setUrlScore,
    sethasPdfUploaded,
    setCanUploadPdfonCloud,
    setisFullReportAvailable,
    setShowQuestionDialog,
    setExpanded,
    setgoogleApiKey,
    setopenNextQuestionDialog,
    setLoadingOpenDialog,
    setEmail,
    setAppLoadingDisease,
    setAppLoadingScore,
    onShowSnackbar,
    onHideSnackbar,
    setAppLoading,
    setHealthScore,
    setDisease,
    setHasFilledDetails,
    setReferesh,
    setSelectedScore,
    refreshHealthScore,
    setFilledScore,
    setInitialPageDetails,
    setInitialPage,
    submitFirstPageDetails,
    setCanDownlaodPdf,
    moveToNextScoreQuestion,
    setNextAvailableQuestion,
    setAppTitle,
    getAnswerPercentage,
    setUserName,
    currentUser,
    setCurrentUser,
    theme,
    setTheme,
    mode,
    setMode,
    noofDialogOpened,
    setNoofDialogOpened,
  };
};

interface Props {
  children: React.ReactNode;
}

export const ProvideApp = ({ children }: Props) => {
  const app = useProvideApp();

  return <appContext.Provider value={app}>{children}</appContext.Provider>;
};

export const useApp = () => {
  return useContext(appContext);
};
