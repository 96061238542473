import { Page, Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import CoverPage from "../assets/CoverPage.png";
import CoverPage2 from "../assets/CoverPage2.png"; // TODO: rever this

const styles = StyleSheet.create({
  body: {
    padding: 0, // Remove any padding
    margin: 0, // Remove any margin
    fontFamily: "Roboto",
  },
  fullPageImageContainer: {
    width: "100%",
    height: "100%",
    position: "absolute", // Ensures the image covers the entire page
    top: 0,
    left: 0,
  },
  fullPageImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover", // Ensures the image covers the entire page without distortion
  },
  textContainer: {
    position: "absolute",
    top: 120,
    right: 30,
    alignItems: "flex-end",
  },
});

export function CustomCoverPage({ userName, theme }) {
  const formatDate = () => {
    const now = new Date();
    return `Date: ${now
      .toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .replace(",", "")}`;
  };

  return (
    <Page style={styles.body}>
      <View style={styles.fullPageImageContainer}>
        <Image
          src={CoverPage2}
          style={{
            height: "100%",
            width: "100%",
          }}
        />
        <View style={styles.textContainer}>
          <Text
            style={{
              color: theme.coverPageText,
              fontSize: 12,
              fontWeight: "500",
            }}
          >
            AI-Powered
          </Text>
          <Text
            style={{ color: theme.textColor, fontSize: 18, fontWeight: "600" }}
          >
            Health Prognosis Report
          </Text>
          <Text style={{ color: theme.textColor, fontSize: 10, marginTop: 20 }}>
            {userName}
          </Text>
          <Text style={{ color: theme.textColor, fontSize: 10 }}>
            PID: ONCxxxxxx | Male
          </Text>
          <Text style={{ color: theme.textColor, fontSize: 10 }}>
            Report Date: {formatDate()}
          </Text>
        </View>
      </View>
    </Page>
  );
}
