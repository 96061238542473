import { Font, PDFViewer, pdf } from "@react-pdf/renderer";
import NotoSans from "../../assets/fonts/NotoSans-Regular.ttf";
import NotoSansBold from "../../assets/fonts/NotoSans-Bold.ttf";
import NotoSansMedium from "../../assets/fonts/NotoSans-Medium.ttf";
import NotoSansSemiBold from "../../assets/fonts/NotoSans-Medium.ttf";

import RobotoRegular from "../../assets/fonts/NotoSans-Regular.ttf";
import RobotoBold from "../../assets/fonts/NotoSans-Bold.ttf";
import RobotoMedium from "../../assets/fonts/NotoSans-Medium.ttf";

import CustomPDF from "../../CustomPDF";
import { useEffect, useRef, useState } from "react";
import FileSaver from "file-saver";
import axios from "axios";
import { useApp } from "../../../hook/check-app";
import { diseaseAPI } from "../../../apis";
import { visibleScoreswithCustomers } from "../../../utils/constants";
import { getRoundedValue } from "../../../utils/helper-functions";
import { calculateScore } from "../../utils/helpers";

Font.register({
  family: "NotoSans",
  fonts: [
    { src: NotoSans },
    { src: NotoSansBold, fontWeight: "bold" },
    { src: NotoSansMedium, fontWeight: "medium" },
    { src: NotoSansSemiBold, fontWeight: "semiBold" },
  ],
  format: "truetype",
});

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoRegular, fontWeight: "normal" }, // Default normal weight
    { src: RobotoBold, fontWeight: "bold" }, // Bold weight
    { src: RobotoMedium, fontWeight: "medium" }, // Medium weight
  ],
  format: "truetype", // Specify the format (optional if it’s detected automatically)
});

const Entry = () => {
  const {
    email: id,
    canDownloadPdf,
    canUploadPdfonCloud,
    setCanUploadPdfonCloud,
    setCanDownlaodPdf,
    currentUser,
  } = useApp();
  const [diseaseReports, setdiseaseReports] = useState(null);
  const [scores, setscores] = useState(null);
  const [firstPageScore, setFirstPageScore] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error1, setError1] = useState(null);
  const [error2, setError2] = useState(null);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const initialLoadDiseae = useRef(true);
  const [isAllScoreFilled, setIsAllScoreFilled] = useState(false);
  const [firstPageWithoutRecommendations, setFirstPageWithoutRecommendations] =
    useState();
  const [firstPageWithRecommendations, setFirstPageWithRecommendations] =
    useState();

  const removeDuplicatesAndEmpty = (arr) => {
    const uniqueItems = Array.from(
      new Set(arr.filter((item) => item.trim() !== ""))
    );
    return uniqueItems.map((item) => item + " ");
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  useEffect(() => {
    if (id && id.length > 0 && userId.length === 0) {
      setUserId(id);
    }
  }, [id]);

  useEffect(() => {
    if (initialLoadDiseae.current) {
      initialLoadDiseae.current = false;

      const fetchDataDisease = async () => {
        try {
          //  TODO: Original implimentation
          // const response1 = await fetch(
          //   `https://madmachines.datasyndicate.in/v1/api/disease_predictions?user_id=${id}`
          // );
          // const diseaseReports = await response1.json();

          // TODO Dummy data for demo
          // -------------------------
          console.log("200-id:", id);

          const response1 = await diseaseAPI.getAllDisease(id);
          console.log("200-response1:", response1);

          const diseaseReports = response1;
          console.log("200-diseaseReports:", diseaseReports);
          // -------------------------

          if (
            diseaseReports &&
            diseaseReports.name &&
            diseaseReports.name.length > 0
          ) {
            setUserName(capitalizeWords(diseaseReports.name));
          }

          if (diseaseReports && diseaseReports.risks) {
            const processedRisks = Object.keys(diseaseReports.risks).reduce(
              (acc, riskLevel) => {
                acc[riskLevel] = diseaseReports.risks[riskLevel].map((risk) => {
                  const updatedRisk = { ...risk };
                  if (risk.about && risk.about.length > 0) {
                    updatedRisk.about = removeDuplicatesAndEmpty(risk.about);
                  }
                  if (risk.recommendations && risk.recommendations.length > 0) {
                    updatedRisk.recommendations = risk.recommendations.map(
                      (recommendation) =>
                        removeDuplicatesAndEmpty(recommendation)
                    );
                  }
                  if (risk["Risk Factors"] && risk["Risk Factors"].length > 0) {
                    updatedRisk["Risk Factors"] = removeDuplicatesAndEmpty(
                      risk["Risk Factors"]
                    );
                  }
                  return updatedRisk;
                });
                return acc;
              },
              {}
            );

            setdiseaseReports({ risks: processedRisks });
          }
        } catch (error) {
          setError1(error.message);
          console.error("Error fetching data:", error);
        }
      };

      // const fetchDataScore = async () => {
      //   try {
      //     const response = await diseaseAPI.getAllScores(id);
      //     const data = response.data;
      //     if (!data || Object.keys(data).length === 0) {
      //       throw new Error("Your Health Score isn't ready yet");
      //     }

      //     // Filter only the scores that are in visibleScoreswithCustomers.healthians
      //     const allowedScores = visibleScoreswithCustomers[currentUser];
      //     const firstPageScoresWithoutRecommendations = [];
      //     const firstPageScoresWithRecommendations = [];
      //     const scoresWithRecommendations = {};

      //     if (
      //       data.recommendations &&
      //       data.recommendations.name &&
      //       data.recommendations.name.length > 0
      //     ) {
      //       setUserName(capitalizeWords(data.recommendations.name));
      //     }
      //     let allScoreFilled = false;
      //     const extracted = Object.keys(data.recommendations).reduce(
      //       (result, scoreName) => {
      //         // if (scoreName !== "name") {
      //         if (!allowedScores.includes(scoreName)) return result;

      //         if (allowedScores.includes(scoreName)) {
      //           const item = data.recommendations[scoreName];
      //           if (
      //             item &&
      //             Object.keys(item).length > 0 &&
      //             item.recommendations
      //           ) {
      //             if (item.label && !allScoreFilled) {
      //               allScoreFilled = true;
      //             }
      //             let updatedScore;
      //             if (item.score != null) {
      //               switch (scoreName) {
      //                 case "Physical Activity Score":
      //                   updatedScore = getRoundedValue(item.score, 45, true);
      //                   break;
      //                 case "Metabolic Score":
      //                   updatedScore = getRoundedValue(item.score, 100, true);
      //                   break;
      //                 case "Stress Score":
      //                   updatedScore = getRoundedValue(item.score, 40, false);
      //                   break;
      //                 case "Sleep Score":
      //                   updatedScore = getRoundedValue(item.score, 18, false);
      //                   break;
      //                 case "Fatty Liver Score":
      //                   updatedScore = getRoundedValue(item.score, 100, false);
      //                   break;
      //               }
      //             }
      //             result.push({
      //               name: scoreName,
      //               score: item.score !== null ? updatedScore : null,
      //               max_score: item.max_score !== null ? item.max_score : null,
      //               label: item.label !== null ? item.label : null,
      //               scoreLabel:
      //                 item.score_label !== null ? item.score_label : null,
      //             });
      //           }
      //         }
      //         return result;
      //       },
      //       []
      //     );
      //     setIsAllScoreFilled(allScoreFilled);

      //     if (extracted.length === 0) {
      //       setError2("Health Score Questions are not filled yet.");
      //       let firstPageScores = [...extracted]; // Start with the existing scores
      //       const defaultContent = "Not Filled";
      //       const keysInA2 = new Set(extracted.map((item) => item.name));

      //       visibleScoreswithCustomers[currentUser].forEach((key) => {
      //         if (!keysInA2.has(key)) {
      //           firstPageScores.push({ name: key, content: defaultContent });
      //         }
      //       });
      //       setFirstPageScore(firstPageScores);
      //       setscores([]); // Clear any existing scores
      //     } else {
      //       const scoresWithRecommendations = Object.keys(
      //         data.recommendations
      //       ).reduce((acc, scoreName) => {
      //         // if (scoreName !== "name") {
      //         if (allowedScores.includes(scoreName)) {
      //           const item = data.recommendations[scoreName];
      //           if (item && Object.keys(item.recommendations).length > 0) {
      //             let updatedScore;
      //             if (item.score !== null) {
      //               switch (scoreName) {
      //                 case "Physical Activity Score":
      //                   updatedScore = getRoundedValue(item.score, 45, true);
      //                   break;
      //                 case "Metabolic Score":
      //                   updatedScore = getRoundedValue(item.score, 100, true);
      //                   break;
      //                 case "Stress Score":
      //                   updatedScore = getRoundedValue(item.score, 40, false);
      //                   break;
      //                 case "Sleep Score":
      //                   updatedScore = getRoundedValue(item.score, 18, false);
      //                   break;
      //                 case "Fatty Liver Score":
      //                   updatedScore = getRoundedValue(item.score, 100, false);
      //                   break;
      //               }
      //             }
      //             acc[scoreName] = { ...item, score: updatedScore };
      //           }
      //         }
      //         return acc;
      //       }, {});

      //       let firstPageScores = [...extracted]; // Start with the existing scores
      //       const defaultContent = "Not Filled";
      //       const keysInA2 = new Set(extracted.map((item) => item.name));

      //       visibleScoreswithCustomers[currentUser].forEach((key) => {
      //         if (!keysInA2.has(key)) {
      //           firstPageScores.push({ name: key, content: defaultContent });
      //         }
      //       });

      //       setscores({ recommendations: scoresWithRecommendations });
      //       setError2(null);
      //       setFirstPageScore(firstPageScores);
      //     }
      //   } catch (error) {
      //     setError2(
      //       "Your Health Score isn't ready yet. Please fill all the Questions"
      //     );
      //     console.error("Error fetching data:", error);
      //   }
      // };

      const fetchDataScore = async () => {
        try {
          const data = await diseaseAPI.getAllScores(id);
          if (!data || Object.keys(data).length === 0) {
            throw new Error("Your Health Score isn't ready yet");
          }

          // Set username if available
          if (data.recommendations?.name) {
            setUserName(capitalizeWords(data.recommendations.name));
          }

          // Good Metabolic Score
          data.recommendations["Metabolic Score"] = {
            ...data.recommendations["Metabolic Score"],
            score:
              data.recommendations["Metabolic Score"].question_responses &&
              data.recommendations["Metabolic Score"].question_responses
                .length === 3
                ? 61
                : null,
            max_score:
              data.recommendations["Metabolic Score"].question_responses &&
              data.recommendations["Metabolic Score"].question_responses
                .length === 3
                ? 100
                : null,
            label:
              data.recommendations["Metabolic Score"].question_responses &&
              data.recommendations["Metabolic Score"].question_responses
                .length === 3
                ? "Good Metabolic Score"
                : null,
            score_label:
              data.recommendations["Metabolic Score"].question_responses &&
              data.recommendations["Metabolic Score"].question_responses
                .length === 3
                ? "Low"
                : null,
            recommendations:
              data.recommendations["Metabolic Score"].question_responses &&
              data.recommendations["Metabolic Score"].question_responses
                .length === 3
                ? {
                    "Risk Factors": [
                      "Obesity",
                      "Sedentary Lifestyle",
                      "Insulin Resistance",
                      "High Blood Pressure",
                      "High Cholesterol",
                      "",
                    ],
                    "About the Score & Risk": [
                      "Good score implies a decreased likelihood of experiencing metabolic abnormalities such as insulin resistance, obesity, and disrupted lipid profiles.",
                      "Higher the score better the metabolic health, unaddressed metabolic abnormalities may progress to disorders like Diabetes Mellitus, CVD, Hypertension, etc.",
                    ],
                    Mitigation_Strategies_Heading: [
                      "To optimize your metabolic health, consider following these recommendations.",
                      "Increased weight is the risk factor for many diseases like diabetes, heart diseases, musculoskeletal disorders like osteoarthritis, sleep disorders like sleep apnea, certain cancers etc.",
                      "Slow & sustainable weight loss should be the target as aggressive weight loss can lead to weight regain.",
                      "Planning meals beforehand helps to avoid excess consumption of unhealthy and junk foods.",
                      "Consuming a variety of grains, legumes, seasonal vegetables and fruits is recommended.",
                      "At least 150 minutes of PA has to be performed in a week. (Recommended)",
                    ],
                    "Score/Label To Display": "Good Metabolic Score",
                  }
                : {},
          };

          // Poor Metabolic Score
          data.recommendations["Metabolic Score"] = {
            ...data.recommendations["Metabolic Score"],
            score:
              data.recommendations["Metabolic Score"].question_responses &&
              data.recommendations["Metabolic Score"].question_responses
                .length === 3
                ? 40
                : null,
            max_score:
              data.recommendations["Metabolic Score"].question_responses &&
              data.recommendations["Metabolic Score"].question_responses
                .length === 3
                ? 100
                : null,
            label:
              data.recommendations["Metabolic Score"].question_responses &&
              data.recommendations["Metabolic Score"].question_responses
                .length === 3
                ? "Poor Metabolic Score"
                : null,
            score_label:
              data.recommendations["Metabolic Score"].question_responses &&
              data.recommendations["Metabolic Score"].question_responses
                .length === 3
                ? "High"
                : null,
            recommendations:
              data.recommendations["Metabolic Score"].question_responses &&
              data.recommendations["Metabolic Score"].question_responses
                .length === 3
                ? {
                    "Risk Factors": [
                      "Obesity",
                      "Sedentary Lifestyle",
                      "Insulin Resistance",
                      "High Blood Pressure",
                      "High Cholesterol",
                    ],
                    "About the Score & Risk": [
                      "Poor score signals a heightened risk of metabolic issues like insulin resistance, obesity, and unhealthy lipid profiles.",
                      "Higher the score better the metabolic health, unaddressed metabolic abnormalities may progress to disorders like Diabetes Mellitus, CVD, Hypertension, etc.",
                    ],
                    Mitigation_Strategies_Heading: [
                      "To improve your metabolic health and reduce future health risks, following these recommendations is crucial.",
                      "Increased weight is the risk factor for many diseases like diabetes, heart diseases, musculoskeletal disorders like osteoarthritis, sleep disorders like sleep apnea, certain cancers etc.",
                      "Slow & sustainable weight loss should be the target as aggressive weight loss can lead to weight regain.",
                      "Planning meals beforehand helps to avoid excess consumption of unhealthy and junk foods.",
                      "Consuming a variety of grains, legumes, seasonal vegetables and fruits is recommended.",
                      "Walking after a meal helps in minimizing the glucose spike.",
                      "At least 150 minutes of PA has to be performed in a week. (Recommended)",
                      "These can help to maintain body composition, glucose regulation and also lipid profile.",
                      "Lean cuts of meat, baked or grilled should be preferred over meats cooked at high temperature & charred excessively.",
                    ],
                    "Score/Label To Display": "Poor Metabolic Score",
                  }
                : {},
          };

          // High Risk Fatty Liver
          data.recommendations["Fatty Liver Score"] = {
            ...data.recommendations["Fatty Liver Score"],
            score:
              data.recommendations["Fatty Liver Score"].question_responses &&
              data.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? 70
                : null,
            max_score:
              data.recommendations["Fatty Liver Score"].question_responses &&
              data.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? 100
                : null,
            label:
              data.recommendations["Fatty Liver Score"].question_responses &&
              data.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? "High Risk Fatty Liver"
                : null,
            score_label:
              data.recommendations["Fatty Liver Score"].question_responses &&
              data.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? "High"
                : null,
            recommendations:
              data.recommendations["Fatty Liver Score"].question_responses &&
              data.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? {
                    "Risk Factors": [
                      "Age",
                      "Genetic Factors",
                      "Poor Diet",
                      "Dyslipidemia",
                      "Insulin Resistance",
                      "Metabolic Syndrome",
                    ],
                    "About the Score & Risk": [
                      "You have a high risk of developing Non Alcoholic Fatty Liver Disease (NAFLD).",
                      "Approx. 38% of Indians suffer from NAFLD.",
                      "Early detection of NAFLD is crucial as it has no proven drug cure, and excess fat accumulation in the liver can lead to irreversible damage.",
                    ],
                    Mitigation_Strategies_Heading: [
                      "To effectively manage your NAFLD risk, it's important to follow these recommendations diligently",
                      "The cornerstone measure for NAFLD prevention involves slow and sustainable weight loss, aiming for 1kg per week of weight loss.",
                      "Even a 5% reduction can significantly improve the condition, the goal here is to maintain the lost weight.",
                      "Active as well as passive smokers are at risk of NAFLD.",
                      "Recommended 150 minutes of weekly physical activity lowers NAFLD risk compared to inactivity.",
                      "Low glycemic index (GI) carbohydrates, MUFAs, PUFAs should be preferred over high GI carbohydrates.",
                      "Red meat should be consumed in limits as it contains high levels of sodium, saturated fats, and trans fats.",
                      "Lean cuts of meat, baked or grilled should be preferred over meats cooked at high temperature & charred excessively.",
                    ],
                    "Score/Label To Display": "High Risk Fatty Liver",
                  }
                : {},
          };

          // Moderate Risk Fatty Liver
          data.recommendations["Fatty Liver Score"] = {
            ...data.recommendations["Fatty Liver Score"],
            score:
              data.recommendations["Fatty Liver Score"].question_responses &&
              data.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? 50
                : null,
            max_score:
              data.recommendations["Fatty Liver Score"].question_responses &&
              data.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? 100
                : null,
            label:
              data.recommendations["Fatty Liver Score"].question_responses &&
              data.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? "Moderate Risk Fatty Liver"
                : null,
            score_label:
              data.recommendations["Fatty Liver Score"].question_responses &&
              data.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? "Medium"
                : null,
            recommendations:
              data.recommendations["Fatty Liver Score"].question_responses &&
              data.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? {
                    "Risk Factors": [
                      "Age",
                      "Genetic Factors",
                      "Poor Diet",
                      "Dyslipidemia",
                      "Insulin Resistance",
                      "Metabolic Syndrome",
                    ],
                    "About the Score & Risk": [
                      "You have a moderate risk of developing Non Alcoholic Fatty Liver Disease (NAFLD).",
                      "Liver fat over 5-10% can cause NAFLD.",
                      "Early detection of NAFLD is crucial as it has no proven drug cure, and excess fat accumulation in the liver can lead to irreversible damage.",
                    ],
                    Mitigation_Strategies_Heading: [
                      "For optimal liver health, especially with a moderate NAFLD risk, following these recommendations is advisable.",
                      "The cornerstone measure for NAFLD prevention involves slow and sustainable weight loss, aiming for 1kg per week of weight loss.",
                      "Even a 5% reduction can significantly improve the condition, the goal here is to maintain the lost weight.",
                      "Active as well as passive smokers are at risk of NAFLD.",
                      "Recommended 150 minutes of weekly physical activity lowers NAFLD risk compared to inactivity.",
                      "Low glycemic index (GI) carbohydrates, MUFAs, PUFAs should be preferred over high GI carbohydrates.",
                      "Red meat should be consumed in limits as it contains high levels of sodium, saturated fats, and trans fats.",
                      "Lean cuts of meat, baked or grilled should be preferred over meats cooked at high temperature & charred excessively.",
                    ],
                    "Score/Label To Display": "Moderate Risk Fatty Liver",
                  }
                : {},
          };

          // Low Risk Fatty Liver
          data.recommendations["Fatty Liver Score"] = {
            ...data.recommendations["Fatty Liver Score"],
            score:
              data.recommendations["Fatty Liver Score"].question_responses &&
              data.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? 0
                : null,
            max_score:
              data.recommendations["Fatty Liver Score"].question_responses &&
              data.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? 100
                : null,
            label:
              data.recommendations["Fatty Liver Score"].question_responses &&
              data.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? "Low Risk Fatty Liver"
                : null,
            score_label:
              data.recommendations["Fatty Liver Score"].question_responses &&
              data.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? "Low"
                : null,
            recommendations:
              data.recommendations["Fatty Liver Score"].question_responses &&
              data.recommendations["Fatty Liver Score"].question_responses
                .length === 3
                ? {
                    "Risk Factors": [
                      "Age",
                      "Genetic Factors",
                      "Poor Diet",
                      "Dyslipidemia",
                      "Insulin Resistance",
                      "Metabolic Syndrome",
                    ],
                    "About the Score & Risk": [
                      "You have a low risk of developing Non Alcoholic Fatty Liver Disease (NAFLD).",
                      "Stable weight and blood glucose lower NAFLD.",
                      "It is characterised by excess of fat accumulation in the liver.",
                    ],
                    Mitigation_Strategies_Heading: [
                      "While your NAFLD risk is low, incorporating these recommendations can further promote overall well-being, including liver health",
                      "Maintaining ideal body weight & body composition will further help to keep risk of NAFLD at lower end of the spectrum.",
                      "Recommended 150 minutes of weekly physical activity also lowers NAFLD risk compared to inactivity.",
                      "Balanced diet containing low glycemic index carbohydrates, proteins & MUFAs & PUFAs will aid in further reducing  risk of NAFLD.",
                    ],
                    "Score/Label To Display": "Low Risk Fatty Liver",
                  }
                : {},
          };

          // Define allowed scores for the customer
          const allowedScores = visibleScoreswithCustomers[currentUser];
          const firstPageScoresWithoutRecommendations = [];
          const firstPageScoresWithRecommendations = [];
          const scoresWithRecommendations = {};
          let allScoreFilled = false;

          // Process recommendations
          Object.keys(data.recommendations).forEach((scoreName) => {
            if (!allowedScores.includes(scoreName)) return;

            const item = data.recommendations[scoreName];
            if (!item || Object.keys(item).length === 0) return;

            // Calculate score for each recommendation
            const updatedScore = {
              name: scoreName,
              score:
                item.score !== null
                  ? calculateScore(scoreName, item.score)
                  : null,
              max_score: item.max_score ?? null,
              label: item.label ?? null,
              scoreLabel: item.score_label ?? null,
              content: item.label ? null : "Not Filled",
            };

            // Check if any score is filled
            if (updatedScore.label && !allScoreFilled) {
              allScoreFilled = true;
            }

            // Separate scores into two categories

            if (updatedScore.label) {
              firstPageScoresWithRecommendations.push(updatedScore);
            } else {
              firstPageScoresWithoutRecommendations.push(updatedScore);
            }

            // Group scores with recommendations
            if (item.recommendations) {
              scoresWithRecommendations[scoreName] = {
                ...item,
                score: calculateScore(scoreName, item.score),
              };
            }
          });

          // Update state with the processed scores
          const firstPageScore = [
            ...firstPageScoresWithoutRecommendations,
            ...firstPageScoresWithRecommendations,
          ];
          setFirstPageWithoutRecommendations(
            firstPageScoresWithoutRecommendations
          );
          setFirstPageWithRecommendations(firstPageScoresWithRecommendations);
          setFirstPageScore(firstPageScore);
          setIsAllScoreFilled(allScoreFilled);

          console.log(
            "firstPageScoresWithoutRecommendations:",
            firstPageScoresWithoutRecommendations
          );
          console.log(
            "firstPageScoresWithRecommendations:",
            firstPageScoresWithRecommendations
          );

          // Set scores with recommendations
          if (Object.keys(scoresWithRecommendations).length > 0) {
            setscores({ recommendations: scoresWithRecommendations });
            setError2(null);
          } else {
            setError2("Health Score Questions are not filled yet.");
            setscores([]);
          }
        } catch (error) {
          setError2(
            "Your Health Score isn't ready yet. Please fill all the Questions."
          );
          console.error("Error fetching data:", error);
        }
      };

      const callPdf = async () => {
        setLoading(true);
        await fetchDataDisease();
        await fetchDataScore();
        setLoading(false);
      };

      if (canDownloadPdf) callPdf();
    }
  }, [canDownloadPdf]);

  useEffect(() => {
    if (!loading && canDownloadPdf) {
      downloadPDF();
      setCanDownlaodPdf(false);
    }
  }, [loading, canDownloadPdf]);

  const downloadPDF = async () => {
    try {
      // Generate the PDF blob

      const blob = await pdf(
        <CustomPDF
          diseaseReports={diseaseReports}
          scores={scores}
          error1={error1}
          error2={error2}
          firstPageScore={firstPageScore}
          userName={userName}
          id={userId}
          isAllScoreFilled={isAllScoreFilled}
          firstPageWithRecommendations={firstPageWithRecommendations}
          firstPageWithoutRecommendations={firstPageWithoutRecommendations}
        />
      ).toBlob();

      // Save the PDF locally if needed
      if (!(canDownloadPdf && canUploadPdfonCloud))
        FileSaver.saveAs(blob, "report.pdf");

      if (canUploadPdfonCloud) {
        console.log("Starting upload on cloud");

        // Convert the blob to a base64 string (keep the full data URL, including MIME type)
        const base64Blob = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob); // Get the full base64 data URL
          reader.onloadend = () => resolve(reader.result); // Keep the entire data URL
          reader.onerror = reject;
        });

        // Strip the `data:application/pdf;base64,` prefix from the base64 string
        const base64Content = base64Blob.split(",")[1]; // Only take the base64 part

        // Send the base64 string to the API
        const payload = {
          client: currentUser === "healthians" ? "madmachines" : currentUser,
          user_id: id, // Changed to match the passed userId prop
          pdf_blob: base64Content, // Use the base64 content without the prefix
        };
        // const response = await diseaseAPI.savePdf(payload);
        console.log("response:", response);
        console.log("PDF uploaded successfully");
        setCanUploadPdfonCloud(false);
      }
    } catch (error) {
      console.error("Error generating or uploading PDF:", error);
      // throw new Error(error.message);
    }
  };

  return <></>;
};

export default Entry;
