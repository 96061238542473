export const DEFAULT_SUMMARY_LIST_DISPLAY_COUNT = 1;

export const API_ENDPOINT = {
  GET_ALL_DISEASE_PREDICTIONS:
    "https://madmachines.datasyndicate.in/v1/api/disease_predictions",
  GET_ALL_SCORES_PREDICTIONS:
    "https://madmachines.datasyndicate.in/v1/api/score_recommendations",
  SAVE_SCORE:
    "https://madmachines.datasyndicate.in/v1/api/save_score_question_response",
  SAVE_USER_DETAILS:
    "https://madmachines.datasyndicate.in/v1/api/save_user_details",
  GET_USER_DETAILS:
    "https://madmachines.datasyndicate.in/v1/api/get_user_details",
  GET_ANSWER_COUNTS_PERCENTAGE:
    "https://madmachines-api-32xupa3pnq-el.a.run.app/v1/api/answered_counts_percentage",
  GET_SCORE_LABELS: "https://madmachines.datasyndicate.in/v1/api/score_labels",
  SAVE_REPORTS:
    "https://madmachines-api-32xupa3pnq-el.a.run.app/v1/api/save_reports",
  PRAKRITI_QUESTIONS:
    "https://madmachines.datasyndicate.in/v1/api/scores_questions",
};

export const visibleScoreswithCustomers = {
  healthians: [
    "Physical Activity Score",
    "Metabolic Score",
    "Sleep Score",
    "Stress Score",
    "Fatty Liver Score",
  ],
  onquest: [
    "Physical Activity Score",
    "Metabolic Score",
    "Sleep Score",
    "Stress Score",
    "Fatty Liver Score",
  ],
  dxAi: [
    "Physical Activity Score",
    "Metabolic Score",
    "Sleep Score",
    "Stress Score",
    "Fatty Liver Score",
  ],
};

// TODO Dummy data for demo
export const diseaseData = {
  "Sundeep_Sahniii": {
    risks: {
      "High Risk": [
        {
          risk_name: "High Risk For CVD",
          about: [
            "You have a very high likelihood of experiencing Cardiovascular disease, or CVD.",
            "CVD refers to problems with the heart and blood vessels.",
            "80% of CVDs can be prevented by minimising risk factors & adopting a healthier lifestyle.",
            "Depending on where the blood vessels are blocked or affected, it can lead to specific issues like heart disease, stroke, or leg circulation problems.",
          ],
          recommendations: [
            [
              "Consume plenty of fruits, vegetables, whole grains and pulses/legumes in diet.",
              "When choosing animal protein sources, choose lean cuts of meat, avoid consuming processed meat and minimise consumption of red meat.",
              "Adequate magnesium intake is essential as it helps to maintain blood pressure and glucose metabolism, both of which are linked to cardiovascular disease (CVD).",
              "Limit food which contain unhealthy fats, such as saturated and trans fats. ",
              "Maintain a healthy weight, if you are overweight or obese, losing even a small amount of weight can help lower blood pressure and CVD risk.",
              "Engage in regular physical activity, aim for at least 30 minutes of moderate-intensity aerobic activity most days of the week.",
              "Smoking is a major risk factor for CVD, if you smoke, quitting is one of the most important steps you can take to improve health.",
              "Limit alcohol intake to reduce blood pressure risk.",
              "Regular check-ups aid in monitoring and managing disease risk, those at high risk are advised to follow up with a physician.",
            ],
          ],
          "Risk Factors": [
            "Diabetes",
            "Obesity",
            "Physical Inactivity",
            "Excessive Alcohol Consumption",
            "Age",
            "Gender : Men",
            "Family History",
            "Genetics",
            "Chronic Inflammation",
          ],
        },

        {
          risk_name: "High Risk Iron Deficiency",
          about: [
            "You have a very high likelihood of developing Iron Deficiency (ID).",
            "ID is a condition when body's iron levels are low but not low enough to impair production of red blood cells to cause anemia.",
            "Consequences of ID can be cognitive impairment, weakened immune system, reduced physical performance, increased risk of pregnancy complications, hair loss etc.",
            "ID if not managed can progress to iron deficiency anemia (IDA).",
          ],
          recommendations: [
            [
              "Given your high risk it is crucial that you follow these recommendations & take measures to lower your risk of developing ID.",
              "Iron present in animal food sources is better absorbed in the body as compared to iron present in plant food sources.",
              "Combine iron-rich foods with vitamin C sources like citrus fruits for better non-heme (plant sources) iron absorption.",
              "Don't consume calcium-rich foods or supplements with iron-rich meals, as calcium can inhibit iron absorption.",
              "Thorough cooking and soaking reduces phytates that inhibit iron absorption.",
              "Inflammation can also affect iron requirements as well as absorption, it is necessary to consume foods and do regular exercise which can help in decreasing inflammation in the body.",
            ],
          ],
          "Risk Factors": [
            "Reduced Iron Absorption",
            "Increased Iron Requirements",
            "Chronic Blood Loss",
            "Chronic Inflammation",
            "Genetic Predisposition",
          ],
        },
      ],
      "Low Risk": [
        {
          risk_name: "Low Risk Of Diabetes",
          about: [
            "You have a low risk of developing diabetes.",
            "Persistent high blood glucose levels (hyperglycemia) can lead to Type 2 Diabetes.",
          ],
          recommendations: [
            [
              "Following these recommendations can help in continued well being & maintain low risk of developing DM.",
              "Practice portion control to avoid overeating, overeating can lead to elevated blood glucose levels.",
              "Snacking when done mindfully can help to avoid overeating, choose snacks which are nutritious and not just calorie dense.",
              "Eat regular, balanced meals throughout the day to regulate blood sugar, avoid skipping meals.",
              "Engage in regular physical activity, aim for at least 150 minutes of moderate-intensity aerobic exercise per week, as recommended by health guidelines.",
              "Achieve and maintain a healthy body composition through a combination of diet and exercise.",
              "Practice stress reduction techniques such as meditation, deep breathing exercises, or yoga, as increased stress hormones can negatively impact blood sugar levels.",
            ],
          ],
          "Risk Factors": [
            "Hormonal Changes",
            "High Sugar & Fat Rich Diet",
            "Physical Inactivity",
            "Stress",
            "Alcohol Consumption",
            "Illness & Infections",
          ],
        },

        {
          risk_name: "Low Risk Of Hypertension",
          about: [
            "You have a low risk indicates lower likelihood of developing Hypertension (HTN).",
            "It is commonly known as high blood pressure and is a chronic health condition characterised by a persistent elevation in arterial pressure.",
            "The majority of individuals with hypertension experience no noticeable symptoms.",
          ],
          recommendations: [
            [
              "Even though your risk for developing HTN is low, following these recommendations can help to maintain optimal health.",
              "Limit caffeine intake and avoid excessive consumption of energy drinks or supplements.",
              "Limit sodium intake to less than 2,300 milligrams (mg) per day, which translates to 5 g i.e. 1 tsp of salt.",
              "Manage stress effectively, chronic stress can contribute to high blood pressure.",
              "Consider incorporating potassium-rich foods into your diet, as potassium can help counteract the effects of sodium on blood pressure.",
              "Limit alcohol intake to reduce blood pressure risk.",
            ],
          ],
          "Risk Factors": [
            "Diabetes",
            "Obesity",
            "Physical Inactivity",
            "Excessive Alcohol Consumption",
            "Age",
            "Gender : Men",
          ],
        },

        {
          risk_name: "Low Risk Of Thyroid Disorders",
          about: [
            "You have a low risk of developing thyroid disorders.",
            "Thyroid hormones regulate metabolism, growth, development, mood, and cardiovascular health by playing a role in various physiological processes.",
          ],
          recommendations: [
            [
              "Ensuring an adequate but not excessive intake of iodine is important for thyroid health, in regions with iodine deficiency, iodized salt or dietary sources of iodine can help prevent thyroid disorders. ",
              "Minimizing exposure to ionizing radiation, particularly in the neck area, is important in preventing radiation-induced thyroid dysfunction.",
              "Chronic stress may contribute to thyroid dysfunction in some individuals therefore managing stress becomes important aspect to reduce risk of thyroid disorders.",
              "If you are someone who is diabetic or has risk of diabetes, it is important that you manage diabetes effectively and check your thyroid at regular intervals.",
            ],
          ],
          "Risk Factors": [
            "Family History",
            "Age",
            "Exposure to Radiation",
            "Iodine Intake",
          ],
        },
      ],
    },
    name: "Sundeep Sahni",
  },
  "Sundeep_Sahni": {
    risks: {
      "High Risk": [
        {
          risk_name: "High Risk For CVD",
          about: [
            "You have a very high likelihood of experiencing Cardiovascular disease, or CVD.",
            "CVD refers to problems with the heart and blood vessels.",
            "80% of CVDs can be prevented by minimising risk factors & adopting a healthier lifestyle.",
            "Depending on where the blood vessels are blocked or affected, it can lead to specific issues like heart disease, stroke, or leg circulation problems.",
          ],
          recommendations: [
            [
              "Consume plenty of fruits, vegetables, whole grains and pulses/legumes in diet.",
              "When choosing animal protein sources, choose lean cuts of meat, avoid consuming processed meat and minimise consumption of red meat.",
              "Adequate magnesium intake is essential as it helps to maintain blood pressure and glucose metabolism, both of which are linked to cardiovascular disease (CVD).",
              "Limit food which contain unhealthy fats, such as saturated and trans fats. ",
              "Maintain a healthy weight, if you are overweight or obese, losing even a small amount of weight can help lower blood pressure and CVD risk.",
              "Engage in regular physical activity, aim for at least 30 minutes of moderate-intensity aerobic activity most days of the week.",
              "Smoking is a major risk factor for CVD, if you smoke, quitting is one of the most important steps you can take to improve health.",
              "Limit alcohol intake to reduce blood pressure risk.",
              "Regular check-ups aid in monitoring and managing disease risk, those at high risk are advised to follow up with a physician.",
            ],
          ],
          "Risk Factors": [
            "Diabetes",
            "Obesity",
            "Physical Inactivity",
            "Excessive Alcohol Consumption",
            "Age",
            "Gender : Men",
            "Family History",
            "Genetics",
            "Chronic Inflammation",
          ],
        },

        {
          risk_name: "High Risk Iron Deficiency",
          about: [
            "You have a very high likelihood of developing Iron Deficiency (ID).",
            "ID is a condition when body's iron levels are low but not low enough to impair production of red blood cells to cause anemia.",
            "Consequences of ID can be cognitive impairment, weakened immune system, reduced physical performance, increased risk of pregnancy complications, hair loss etc.",
            "ID if not managed can progress to iron deficiency anemia (IDA).",
          ],
          recommendations: [
            [
              "Given your high risk it is crucial that you follow these recommendations & take measures to lower your risk of developing ID.",
              "Iron present in animal food sources is better absorbed in the body as compared to iron present in plant food sources.",
              "Combine iron-rich foods with vitamin C sources like citrus fruits for better non-heme (plant sources) iron absorption.",
              "Don't consume calcium-rich foods or supplements with iron-rich meals, as calcium can inhibit iron absorption.",
              "Thorough cooking and soaking reduces phytates that inhibit iron absorption.",
              "Inflammation can also affect iron requirements as well as absorption, it is necessary to consume foods and do regular exercise which can help in decreasing inflammation in the body.",
            ],
          ],
          "Risk Factors": [
            "Reduced Iron Absorption",
            "Increased Iron Requirements",
            "Chronic Blood Loss",
            "Chronic Inflammation",
            "Genetic Predisposition",
          ],
        },
      ],
      "Low Risk": [
        {
          risk_name: "Low Risk Of Diabetes",
          about: [
            "You have a low risk of developing diabetes.",
            "Persistent high blood glucose levels (hyperglycemia) can lead to Type 2 Diabetes.",
          ],
          recommendations: [
            [
              "Following these recommendations can help in continued well being & maintain low risk of developing DM.",
              "Practice portion control to avoid overeating, overeating can lead to elevated blood glucose levels.",
              "Snacking when done mindfully can help to avoid overeating, choose snacks which are nutritious and not just calorie dense.",
              "Eat regular, balanced meals throughout the day to regulate blood sugar, avoid skipping meals.",
              "Engage in regular physical activity, aim for at least 150 minutes of moderate-intensity aerobic exercise per week, as recommended by health guidelines.",
              "Achieve and maintain a healthy body composition through a combination of diet and exercise.",
              "Practice stress reduction techniques such as meditation, deep breathing exercises, or yoga, as increased stress hormones can negatively impact blood sugar levels.",
            ],
          ],
          "Risk Factors": [
            "Hormonal Changes",
            "High Sugar & Fat Rich Diet",
            "Physical Inactivity",
            "Stress",
            "Alcohol Consumption",
            "Illness & Infections",
          ],
        },

        {
          risk_name: "Low Risk Of Hypertension",
          about: [
            "You have a low risk indicates lower likelihood of developing Hypertension (HTN).",
            "It is commonly known as high blood pressure and is a chronic health condition characterised by a persistent elevation in arterial pressure.",
            "The majority of individuals with hypertension experience no noticeable symptoms.",
          ],
          recommendations: [
            [
              "Even though your risk for developing HTN is low, following these recommendations can help to maintain optimal health.",
              "Limit caffeine intake and avoid excessive consumption of energy drinks or supplements.",
              "Limit sodium intake to less than 2,300 milligrams (mg) per day, which translates to 5 g i.e. 1 tsp of salt.",
              "Manage stress effectively, chronic stress can contribute to high blood pressure.",
              "Consider incorporating potassium-rich foods into your diet, as potassium can help counteract the effects of sodium on blood pressure.",
              "Limit alcohol intake to reduce blood pressure risk.",
            ],
          ],
          "Risk Factors": [
            "Diabetes",
            "Obesity",
            "Physical Inactivity",
            "Excessive Alcohol Consumption",
            "Age",
            "Gender : Men",
          ],
        },

        {
          risk_name: "Low Risk Of Thyroid Disorders",
          about: [
            "You have a low risk of developing thyroid disorders.",
            "Thyroid hormones regulate metabolism, growth, development, mood, and cardiovascular health by playing a role in various physiological processes.",
          ],
          recommendations: [
            [
              "Ensuring an adequate but not excessive intake of iodine is important for thyroid health, in regions with iodine deficiency, iodized salt or dietary sources of iodine can help prevent thyroid disorders. ",
              "Minimizing exposure to ionizing radiation, particularly in the neck area, is important in preventing radiation-induced thyroid dysfunction.",
              "Chronic stress may contribute to thyroid dysfunction in some individuals therefore managing stress becomes important aspect to reduce risk of thyroid disorders.",
              "If you are someone who is diabetic or has risk of diabetes, it is important that you manage diabetes effectively and check your thyroid at regular intervals.",
            ],
          ],
          "Risk Factors": [
            "Family History",
            "Age",
            "Exposure to Radiation",
            "Iodine Intake",
          ],
        },
      ],
    },
    name: "Sundeep Sahni",
  },
  "Sundeep  Sahni": {
    risks: {
      "High Risk": [
        {
          risk_name: "High Risk For CVD",
          about: [
            "You have a very high likelihood of experiencing Cardiovascular disease, or CVD.",
            "CVD refers to problems with the heart and blood vessels.",
            "80% of CVDs can be prevented by minimising risk factors & adopting a healthier lifestyle.",
            "Depending on where the blood vessels are blocked or affected, it can lead to specific issues like heart disease, stroke, or leg circulation problems.",
          ],
          recommendations: [
            [
              "Consume plenty of fruits, vegetables, whole grains and pulses/legumes in diet.",
              "When choosing animal protein sources, choose lean cuts of meat, avoid consuming processed meat and minimise consumption of red meat.",
              "Adequate magnesium intake is essential as it helps to maintain blood pressure and glucose metabolism, both of which are linked to cardiovascular disease (CVD).",
              "Limit food which contain unhealthy fats, such as saturated and trans fats. ",
              "Maintain a healthy weight, if you are overweight or obese, losing even a small amount of weight can help lower blood pressure and CVD risk.",
              "Engage in regular physical activity, aim for at least 30 minutes of moderate-intensity aerobic activity most days of the week.",
              "Smoking is a major risk factor for CVD, if you smoke, quitting is one of the most important steps you can take to improve health.",
              "Limit alcohol intake to reduce blood pressure risk.",
              "Regular check-ups aid in monitoring and managing disease risk, those at high risk are advised to follow up with a physician.",
            ],
          ],
          "Risk Factors": [
            "Diabetes",
            "Obesity",
            "Physical Inactivity",
            "Excessive Alcohol Consumption",
            "Age",
            "Gender : Men",
            "Family History",
            "Genetics",
            "Chronic Inflammation",
          ],
        },

        {
          risk_name: "High Risk Iron Deficiency",
          about: [
            "You have a very high likelihood of developing Iron Deficiency (ID).",
            "ID is a condition when body's iron levels are low but not low enough to impair production of red blood cells to cause anemia.",
            "Consequences of ID can be cognitive impairment, weakened immune system, reduced physical performance, increased risk of pregnancy complications, hair loss etc.",
            "ID if not managed can progress to iron deficiency anemia (IDA).",
          ],
          recommendations: [
            [
              "Given your high risk it is crucial that you follow these recommendations & take measures to lower your risk of developing ID.",
              "Iron present in animal food sources is better absorbed in the body as compared to iron present in plant food sources.",
              "Combine iron-rich foods with vitamin C sources like citrus fruits for better non-heme (plant sources) iron absorption.",
              "Don't consume calcium-rich foods or supplements with iron-rich meals, as calcium can inhibit iron absorption.",
              "Thorough cooking and soaking reduces phytates that inhibit iron absorption.",
              "Inflammation can also affect iron requirements as well as absorption, it is necessary to consume foods and do regular exercise which can help in decreasing inflammation in the body.",
            ],
          ],
          "Risk Factors": [
            "Reduced Iron Absorption",
            "Increased Iron Requirements",
            "Chronic Blood Loss",
            "Chronic Inflammation",
            "Genetic Predisposition",
          ],
        },
      ],
      "Low Risk": [
        {
          risk_name: "Low Risk Of Diabetes",
          about: [
            "You have a low risk of developing diabetes.",
            "Persistent high blood glucose levels (hyperglycemia) can lead to Type 2 Diabetes.",
          ],
          recommendations: [
            [
              "Following these recommendations can help in continued well being & maintain low risk of developing DM.",
              "Practice portion control to avoid overeating, overeating can lead to elevated blood glucose levels.",
              "Snacking when done mindfully can help to avoid overeating, choose snacks which are nutritious and not just calorie dense.",
              "Eat regular, balanced meals throughout the day to regulate blood sugar, avoid skipping meals.",
              "Engage in regular physical activity, aim for at least 150 minutes of moderate-intensity aerobic exercise per week, as recommended by health guidelines.",
              "Achieve and maintain a healthy body composition through a combination of diet and exercise.",
              "Practice stress reduction techniques such as meditation, deep breathing exercises, or yoga, as increased stress hormones can negatively impact blood sugar levels.",
            ],
          ],
          "Risk Factors": [
            "Hormonal Changes",
            "High Sugar & Fat Rich Diet",
            "Physical Inactivity",
            "Stress",
            "Alcohol Consumption",
            "Illness & Infections",
          ],
        },

        {
          risk_name: "Low Risk Of Hypertension",
          about: [
            "You have a low risk indicates lower likelihood of developing Hypertension (HTN).",
            "It is commonly known as high blood pressure and is a chronic health condition characterised by a persistent elevation in arterial pressure.",
            "The majority of individuals with hypertension experience no noticeable symptoms.",
          ],
          recommendations: [
            [
              "Even though your risk for developing HTN is low, following these recommendations can help to maintain optimal health.",
              "Limit caffeine intake and avoid excessive consumption of energy drinks or supplements.",
              "Limit sodium intake to less than 2,300 milligrams (mg) per day, which translates to 5 g i.e. 1 tsp of salt.",
              "Manage stress effectively, chronic stress can contribute to high blood pressure.",
              "Consider incorporating potassium-rich foods into your diet, as potassium can help counteract the effects of sodium on blood pressure.",
              "Limit alcohol intake to reduce blood pressure risk.",
            ],
          ],
          "Risk Factors": [
            "Diabetes",
            "Obesity",
            "Physical Inactivity",
            "Excessive Alcohol Consumption",
            "Age",
            "Gender : Men",
          ],
        },

        {
          risk_name: "Low Risk Of Thyroid Disorders",
          about: [
            "You have a low risk of developing thyroid disorders.",
            "Thyroid hormones regulate metabolism, growth, development, mood, and cardiovascular health by playing a role in various physiological processes.",
          ],
          recommendations: [
            [
              "Ensuring an adequate but not excessive intake of iodine is important for thyroid health, in regions with iodine deficiency, iodized salt or dietary sources of iodine can help prevent thyroid disorders. ",
              "Minimizing exposure to ionizing radiation, particularly in the neck area, is important in preventing radiation-induced thyroid dysfunction.",
              "Chronic stress may contribute to thyroid dysfunction in some individuals therefore managing stress becomes important aspect to reduce risk of thyroid disorders.",
              "If you are someone who is diabetic or has risk of diabetes, it is important that you manage diabetes effectively and check your thyroid at regular intervals.",
            ],
          ],
          "Risk Factors": [
            "Family History",
            "Age",
            "Exposure to Radiation",
            "Iodine Intake",
          ],
        },
      ],
    },
    name: "Sundeep Sahni",
  },
  "Aditi_onquest": {
    risks: {
      "High Risk": [
        {
          risk_name: "High Risk For CVD",
          about: [
            "You have a very high likelihood of experiencing Cardiovascular disease, or CVD.",
            "CVD refers to problems with the heart and blood vessels.",
            "80% of CVDs can be prevented by minimising risk factors & adopting a healthier lifestyle.",
            "Depending on where the blood vessels are blocked or affected, it can lead to specific issues like heart disease, stroke, or leg circulation problems.",
          ],
          recommendations: [
            [
              "Consume plenty of fruits, vegetables, whole grains and pulses/legumes in diet.",
              "When choosing animal protein sources, choose lean cuts of meat, avoid consuming processed meat and minimise consumption of red meat.",
              "Adequate magnesium intake is essential as it helps to maintain blood pressure and glucose metabolism, both of which are linked to cardiovascular disease (CVD).",
              "Limit food which contain unhealthy fats, such as saturated and trans fats. ",
              "Maintain a healthy weight, if you are overweight or obese, losing even a small amount of weight can help lower blood pressure and CVD risk.",
              "Engage in regular physical activity, aim for at least 30 minutes of moderate-intensity aerobic activity most days of the week.",
              "Smoking is a major risk factor for CVD, if you smoke, quitting is one of the most important steps you can take to improve health.",
              "Limit alcohol intake to reduce blood pressure risk.",
              "Regular check-ups aid in monitoring and managing disease risk, those at high risk are advised to follow up with a physician.",
            ],
          ],
          "Risk Factors": [
            "Diabetes",
            "Obesity",
            "Physical Inactivity",
            "Excessive Alcohol Consumption",
            "Age",
            "Gender : Men",
            "Family History",
            "Genetics",
            "Chronic Inflammation",
          ],
        },

        {
          risk_name: "High Risk Iron Deficiency",
          about: [
            "You have a very high likelihood of developing Iron Deficiency (ID).",
            "ID is a condition when body's iron levels are low but not low enough to impair production of red blood cells to cause anemia.",
            "Consequences of ID can be cognitive impairment, weakened immune system, reduced physical performance, increased risk of pregnancy complications, hair loss etc.",
            "ID if not managed can progress to iron deficiency anemia (IDA).",
          ],
          recommendations: [
            [
              "Given your high risk it is crucial that you follow these recommendations & take measures to lower your risk of developing ID.",
              "Iron present in animal food sources is better absorbed in the body as compared to iron present in plant food sources.",
              "Combine iron-rich foods with vitamin C sources like citrus fruits for better non-heme (plant sources) iron absorption.",
              "Don't consume calcium-rich foods or supplements with iron-rich meals, as calcium can inhibit iron absorption.",
              "Thorough cooking and soaking reduces phytates that inhibit iron absorption.",
              "Inflammation can also affect iron requirements as well as absorption, it is necessary to consume foods and do regular exercise which can help in decreasing inflammation in the body.",
            ],
          ],
          "Risk Factors": [
            "Reduced Iron Absorption",
            "Increased Iron Requirements",
            "Chronic Blood Loss",
            "Chronic Inflammation",
            "Genetic Predisposition",
          ],
        },
      ],
      "Low Risk": [
        {
          risk_name: "Low Risk Of Diabetes",
          about: [
            "You have a low risk of developing diabetes.",
            "Persistent high blood glucose levels (hyperglycemia) can lead to Type 2 Diabetes.",
          ],
          recommendations: [
            [
              "Following these recommendations can help in continued well being & maintain low risk of developing DM.",
              "Practice portion control to avoid overeating, overeating can lead to elevated blood glucose levels.",
              "Snacking when done mindfully can help to avoid overeating, choose snacks which are nutritious and not just calorie dense.",
              "Eat regular, balanced meals throughout the day to regulate blood sugar, avoid skipping meals.",
              "Engage in regular physical activity, aim for at least 150 minutes of moderate-intensity aerobic exercise per week, as recommended by health guidelines.",
              "Achieve and maintain a healthy body composition through a combination of diet and exercise.",
              "Practice stress reduction techniques such as meditation, deep breathing exercises, or yoga, as increased stress hormones can negatively impact blood sugar levels.",
            ],
          ],
          "Risk Factors": [
            "Hormonal Changes",
            "High Sugar & Fat Rich Diet",
            "Physical Inactivity",
            "Stress",
            "Alcohol Consumption",
            "Illness & Infections",
          ],
        },

        {
          risk_name: "Low Risk Of Hypertension",
          about: [
            "You have a low risk indicates lower likelihood of developing Hypertension (HTN).",
            "It is commonly known as high blood pressure and is a chronic health condition characterised by a persistent elevation in arterial pressure.",
            "The majority of individuals with hypertension experience no noticeable symptoms.",
          ],
          recommendations: [
            [
              "Even though your risk for developing HTN is low, following these recommendations can help to maintain optimal health.",
              "Limit caffeine intake and avoid excessive consumption of energy drinks or supplements.",
              "Limit sodium intake to less than 2,300 milligrams (mg) per day, which translates to 5 g i.e. 1 tsp of salt.",
              "Manage stress effectively, chronic stress can contribute to high blood pressure.",
              "Consider incorporating potassium-rich foods into your diet, as potassium can help counteract the effects of sodium on blood pressure.",
              "Limit alcohol intake to reduce blood pressure risk.",
            ],
          ],
          "Risk Factors": [
            "Diabetes",
            "Obesity",
            "Physical Inactivity",
            "Excessive Alcohol Consumption",
            "Age",
            "Gender : Men",
          ],
        },

        {
          risk_name: "Low Risk Of Thyroid Disorders",
          about: [
            "You have a low risk of developing thyroid disorders.",
            "Thyroid hormones regulate metabolism, growth, development, mood, and cardiovascular health by playing a role in various physiological processes.",
          ],
          recommendations: [
            [
              "Ensuring an adequate but not excessive intake of iodine is important for thyroid health, in regions with iodine deficiency, iodized salt or dietary sources of iodine can help prevent thyroid disorders. ",
              "Minimizing exposure to ionizing radiation, particularly in the neck area, is important in preventing radiation-induced thyroid dysfunction.",
              "Chronic stress may contribute to thyroid dysfunction in some individuals therefore managing stress becomes important aspect to reduce risk of thyroid disorders.",
              "If you are someone who is diabetic or has risk of diabetes, it is important that you manage diabetes effectively and check your thyroid at regular intervals.",
            ],
          ],
          "Risk Factors": [
            "Family History",
            "Age",
            "Exposure to Radiation",
            "Iodine Intake",
          ],
        },
      ],
    },
    name: "Aditi",
  },
  "Aakash  Shivanshu": {
    risks: {
      "High Risk": [
        {
          risk_name: "High Risk For CVD",
          about: [
            "You have a very high likelihood of experiencing Cardiovascular disease, or CVD.",
            "CVD refers to problems with the heart and blood vessels.",
            "80% of CVDs can be prevented by minimising risk factors & adopting a healthier lifestyle.",
            "Depending on where the blood vessels are blocked or affected, it can lead to specific issues like heart disease, stroke, or leg circulation problems.",
          ],
          recommendations: [
            [
              "Consume plenty of fruits, vegetables, whole grains and pulses/legumes in diet.",
              "When choosing animal protein sources, choose lean cuts of meat, avoid consuming processed meat and minimise consumption of red meat.",
              "Adequate magnesium intake is essential as it helps to maintain blood pressure and glucose metabolism, both of which are linked to cardiovascular disease (CVD).",
              "Limit food which contain unhealthy fats, such as saturated and trans fats. ",
              "Maintain a healthy weight, if you are overweight or obese, losing even a small amount of weight can help lower blood pressure and CVD risk.",
              "Engage in regular physical activity, aim for at least 30 minutes of moderate-intensity aerobic activity most days of the week.",
              "Smoking is a major risk factor for CVD, if you smoke, quitting is one of the most important steps you can take to improve health.",
              "Limit alcohol intake to reduce blood pressure risk.",
              "Regular check-ups aid in monitoring and managing disease risk, those at high risk are advised to follow up with a physician.",
            ],
          ],
          "Risk Factors": [
            "Diabetes",
            "Obesity",
            "Physical Inactivity",
            "Excessive Alcohol Consumption",
            "Age",
            "Gender : Men",
            "Family History",
            "Genetics",
            "Chronic Inflammation",
          ],
        },

        {
          risk_name: "High Risk Iron Deficiency",
          about: [
            "You have a very high likelihood of developing Iron Deficiency (ID).",
            "ID is a condition when body's iron levels are low but not low enough to impair production of red blood cells to cause anemia.",
            "Consequences of ID can be cognitive impairment, weakened immune system, reduced physical performance, increased risk of pregnancy complications, hair loss etc.",
            "ID if not managed can progress to iron deficiency anemia (IDA).",
          ],
          recommendations: [
            [
              "Given your high risk it is crucial that you follow these recommendations & take measures to lower your risk of developing ID.",
              "Iron present in animal food sources is better absorbed in the body as compared to iron present in plant food sources.",
              "Combine iron-rich foods with vitamin C sources like citrus fruits for better non-heme (plant sources) iron absorption.",
              "Don't consume calcium-rich foods or supplements with iron-rich meals, as calcium can inhibit iron absorption.",
              "Thorough cooking and soaking reduces phytates that inhibit iron absorption.",
              "Inflammation can also affect iron requirements as well as absorption, it is necessary to consume foods and do regular exercise which can help in decreasing inflammation in the body.",
            ],
          ],
          "Risk Factors": [
            "Reduced Iron Absorption",
            "Increased Iron Requirements",
            "Chronic Blood Loss",
            "Chronic Inflammation",
            "Genetic Predisposition",
          ],
        },
      ],
      "Low Risk": [
        {
          risk_name: "Low Risk Of Diabetes",
          about: [
            "You have a low risk of developing diabetes.",
            "Persistent high blood glucose levels (hyperglycemia) can lead to Type 2 Diabetes.",
          ],
          recommendations: [
            [
              "Following these recommendations can help in continued well being & maintain low risk of developing DM.",
              "Practice portion control to avoid overeating, overeating can lead to elevated blood glucose levels.",
              "Snacking when done mindfully can help to avoid overeating, choose snacks which are nutritious and not just calorie dense.",
              "Eat regular, balanced meals throughout the day to regulate blood sugar, avoid skipping meals.",
              "Engage in regular physical activity, aim for at least 150 minutes of moderate-intensity aerobic exercise per week, as recommended by health guidelines.",
              "Achieve and maintain a healthy body composition through a combination of diet and exercise.",
              "Practice stress reduction techniques such as meditation, deep breathing exercises, or yoga, as increased stress hormones can negatively impact blood sugar levels.",
            ],
          ],
          "Risk Factors": [
            "Hormonal Changes",
            "High Sugar & Fat Rich Diet",
            "Physical Inactivity",
            "Stress",
            "Alcohol Consumption",
            "Illness & Infections",
          ],
        },

        {
          risk_name: "Low Risk Of Hypertension",
          about: [
            "You have a low risk indicates lower likelihood of developing Hypertension (HTN).",
            "It is commonly known as high blood pressure and is a chronic health condition characterised by a persistent elevation in arterial pressure.",
            "The majority of individuals with hypertension experience no noticeable symptoms.",
          ],
          recommendations: [
            [
              "Even though your risk for developing HTN is low, following these recommendations can help to maintain optimal health.",
              "Limit caffeine intake and avoid excessive consumption of energy drinks or supplements.",
              "Limit sodium intake to less than 2,300 milligrams (mg) per day, which translates to 5 g i.e. 1 tsp of salt.",
              "Manage stress effectively, chronic stress can contribute to high blood pressure.",
              "Consider incorporating potassium-rich foods into your diet, as potassium can help counteract the effects of sodium on blood pressure.",
              "Limit alcohol intake to reduce blood pressure risk.",
            ],
          ],
          "Risk Factors": [
            "Diabetes",
            "Obesity",
            "Physical Inactivity",
            "Excessive Alcohol Consumption",
            "Age",
            "Gender : Men",
          ],
        },

        {
          risk_name: "Low Risk Of Thyroid Disorders",
          about: [
            "You have a low risk of developing thyroid disorders.",
            "Thyroid hormones regulate metabolism, growth, development, mood, and cardiovascular health by playing a role in various physiological processes.",
          ],
          recommendations: [
            [
              "Ensuring an adequate but not excessive intake of iodine is important for thyroid health, in regions with iodine deficiency, iodized salt or dietary sources of iodine can help prevent thyroid disorders. ",
              "Minimizing exposure to ionizing radiation, particularly in the neck area, is important in preventing radiation-induced thyroid dysfunction.",
              "Chronic stress may contribute to thyroid dysfunction in some individuals therefore managing stress becomes important aspect to reduce risk of thyroid disorders.",
              "If you are someone who is diabetic or has risk of diabetes, it is important that you manage diabetes effectively and check your thyroid at regular intervals.",
            ],
          ],
          "Risk Factors": [
            "Family History",
            "Age",
            "Exposure to Radiation",
            "Iodine Intake",
          ],
        },
      ],
    },
    name: "Aakash",
  },
  "customer_id_12": {
    risks: {
      "High Risk": [
        {
          risk_name: "High Risk For CVD",
          about: [
            "You have a very high likelihood of experiencing Cardiovascular disease, or CVD.",
            "CVD refers to problems with the heart and blood vessels.",
            "80% of CVDs can be prevented by minimising risk factors & adopting a healthier lifestyle.",
            "Depending on where the blood vessels are blocked or affected, it can lead to specific issues like heart disease, stroke, or leg circulation problems.",
          ],
          recommendations: [
            [
              "Consume plenty of fruits, vegetables, whole grains and pulses/legumes in diet.",
              "When choosing animal protein sources, choose lean cuts of meat, avoid consuming processed meat and minimise consumption of red meat.",
              "Adequate magnesium intake is essential as it helps to maintain blood pressure and glucose metabolism, both of which are linked to cardiovascular disease (CVD).",
              "Limit food which contain unhealthy fats, such as saturated and trans fats. ",
              "Maintain a healthy weight, if you are overweight or obese, losing even a small amount of weight can help lower blood pressure and CVD risk.",
              "Engage in regular physical activity, aim for at least 30 minutes of moderate-intensity aerobic activity most days of the week.",
              "Smoking is a major risk factor for CVD, if you smoke, quitting is one of the most important steps you can take to improve health.",
              "Limit alcohol intake to reduce blood pressure risk.",
              "Regular check-ups aid in monitoring and managing disease risk, those at high risk are advised to follow up with a physician.",
            ],
          ],
          "Risk Factors": [
            "Diabetes",
            "Obesity",
            "Physical Inactivity",
            "Excessive Alcohol Consumption",
            "Age",
            "Gender : Men",
            "Family History",
            "Genetics",
            "Chronic Inflammation",
          ],
        },

        {
          risk_name: "High Risk Iron Deficiency",
          about: [
            "You have a very high likelihood of developing Iron Deficiency (ID).",
            "ID is a condition when body's iron levels are low but not low enough to impair production of red blood cells to cause anemia.",
            "Consequences of ID can be cognitive impairment, weakened immune system, reduced physical performance, increased risk of pregnancy complications, hair loss etc.",
            "ID if not managed can progress to iron deficiency anemia (IDA).",
          ],
          recommendations: [
            [
              "Given your high risk it is crucial that you follow these recommendations & take measures to lower your risk of developing ID.",
              "Iron present in animal food sources is better absorbed in the body as compared to iron present in plant food sources.",
              "Combine iron-rich foods with vitamin C sources like citrus fruits for better non-heme (plant sources) iron absorption.",
              "Don't consume calcium-rich foods or supplements with iron-rich meals, as calcium can inhibit iron absorption.",
              "Thorough cooking and soaking reduces phytates that inhibit iron absorption.",
              "Inflammation can also affect iron requirements as well as absorption, it is necessary to consume foods and do regular exercise which can help in decreasing inflammation in the body.",
            ],
          ],
          "Risk Factors": [
            "Reduced Iron Absorption",
            "Increased Iron Requirements",
            "Chronic Blood Loss",
            "Chronic Inflammation",
            "Genetic Predisposition",
          ],
        },
      ],
      "Low Risk": [
        {
          risk_name: "Low Risk Of Diabetes",
          about: [
            "You have a low risk of developing diabetes.",
            "Persistent high blood glucose levels (hyperglycemia) can lead to Type 2 Diabetes.",
          ],
          recommendations: [
            [
              "Following these recommendations can help in continued well being & maintain low risk of developing DM.",
              "Practice portion control to avoid overeating, overeating can lead to elevated blood glucose levels.",
              "Snacking when done mindfully can help to avoid overeating, choose snacks which are nutritious and not just calorie dense.",
              "Eat regular, balanced meals throughout the day to regulate blood sugar, avoid skipping meals.",
              "Engage in regular physical activity, aim for at least 150 minutes of moderate-intensity aerobic exercise per week, as recommended by health guidelines.",
              "Achieve and maintain a healthy body composition through a combination of diet and exercise.",
              "Practice stress reduction techniques such as meditation, deep breathing exercises, or yoga, as increased stress hormones can negatively impact blood sugar levels.",
            ],
          ],
          "Risk Factors": [
            "Hormonal Changes",
            "High Sugar & Fat Rich Diet",
            "Physical Inactivity",
            "Stress",
            "Alcohol Consumption",
            "Illness & Infections",
          ],
        },

        {
          risk_name: "Low Risk Of Hypertension",
          about: [
            "You have a low risk indicates lower likelihood of developing Hypertension (HTN).",
            "It is commonly known as high blood pressure and is a chronic health condition characterised by a persistent elevation in arterial pressure.",
            "The majority of individuals with hypertension experience no noticeable symptoms.",
          ],
          recommendations: [
            [
              "Even though your risk for developing HTN is low, following these recommendations can help to maintain optimal health.",
              "Limit caffeine intake and avoid excessive consumption of energy drinks or supplements.",
              "Limit sodium intake to less than 2,300 milligrams (mg) per day, which translates to 5 g i.e. 1 tsp of salt.",
              "Manage stress effectively, chronic stress can contribute to high blood pressure.",
              "Consider incorporating potassium-rich foods into your diet, as potassium can help counteract the effects of sodium on blood pressure.",
              "Limit alcohol intake to reduce blood pressure risk.",
            ],
          ],
          "Risk Factors": [
            "Diabetes",
            "Obesity",
            "Physical Inactivity",
            "Excessive Alcohol Consumption",
            "Age",
            "Gender : Men",
          ],
        },

        {
          risk_name: "Low Risk Of Thyroid Disorders",
          about: [
            "You have a low risk of developing thyroid disorders.",
            "Thyroid hormones regulate metabolism, growth, development, mood, and cardiovascular health by playing a role in various physiological processes.",
          ],
          recommendations: [
            [
              "Ensuring an adequate but not excessive intake of iodine is important for thyroid health, in regions with iodine deficiency, iodized salt or dietary sources of iodine can help prevent thyroid disorders. ",
              "Minimizing exposure to ionizing radiation, particularly in the neck area, is important in preventing radiation-induced thyroid dysfunction.",
              "Chronic stress may contribute to thyroid dysfunction in some individuals therefore managing stress becomes important aspect to reduce risk of thyroid disorders.",
              "If you are someone who is diabetic or has risk of diabetes, it is important that you manage diabetes effectively and check your thyroid at regular intervals.",
            ],
          ],
          "Risk Factors": [
            "Family History",
            "Age",
            "Exposure to Radiation",
            "Iodine Intake",
          ],
        },
      ],
    },
    name: "Amrit",
  },
};
